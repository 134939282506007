@import './src/assets/syles/variables';

.invalid {
  border-color: $passion-red !important;
  padding-right: calc(1.5em + 0.75rem);
  background-repeat: no-repeat;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  background-position: 98%;
}

.with-errors input {
  @extend .invalid;
}

.invalid-message {
  color: #f43d4b !important;
  margin-top: 5px;
  font-size: 12px;
}

.input-text {
  align-items: center;
  background-color: $white;
  border: 1px solid $fade;
  display: flex;
  appearance: none;
  height: 35px;
  outline: none;
  padding: 0.75rem;
  width: 100%;
  font-size: 13px;
  &:hover,
  &:active {
    border: 1px solid #bfbfbf;
  }
  &:hover,
  &:active {
    border: 1px solid #bfbfbf;
  }

  &::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    color: $placeholder-color;
  }
  &:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: $placeholder-color;
    opacity: 1;
  }
  &::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: $placeholder-color;
    opacity: 1;
  }
  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: $placeholder-color;
  }
  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: $placeholder-color;
  }
  &::placeholder {
    /* Most modern browsers support this now. */
    color: $placeholder-color;
  }
}

.disabled-input {
  input {
    background-color: #dfdfdf;
    color: #969696;
    opacity: 1 !important;
  }
}

.custom-input-text {
  .close-icon-container {
    &:after {
      content: ' ';
      border-right: 1px solid #ededed;
      position: absolute;
      right: 35px;
      margin-top: -7px;
      top: 50%;
      height: 14px;
      margin-right: -3px;
    }
  }
}

.box-with-icon {
  position: relative;
  margin-bottom: 1rem;
  input {
    padding-right: 36px;
  }

  svg,
  .close-icon {
    position: absolute;
    top: 50%;
    margin-top: -7px;
    right: 10px;
    width: 14px;
    height: auto;
    max-height: 14px;
  }
  .close-icon {
    cursor: pointer;
    margin-top: -6px;
    right: 35px;
    width: 12px;
    height: auto;
    max-height: 12px;
    margin-right: 5px;
  }
}
