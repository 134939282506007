@import '~breakpoint-sass/stylesheets/_breakpoint';
@import './src/assets/syles/utils/mixins';
@import './src/assets/syles/variables';

%unread {
  background-color: #da316d;
  height: 5px;
  width: 5px;
  border-radius: 50%;
  display: inline-block;
}

%text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

%rc-tooltip {
  font-size: 10px !important;
  font-family: $font-lato;
  border-radius: 5px !important;
}

%blue-link {
  font-family: $font-concourse;
  color: $link;
  font-size: 10px;
  letter-spacing: 0;
  text-transform: uppercase;
  opacity: 1;
  text-decoration: none;

  &:hover {
    color: $blue-link-hover;
  }
}

%specialty-tag-card {
  background: $brand-agency;
  padding: 3px;
  font-size: 8px;
  font-weight: 500;
  color: $white;
  text-transform: uppercase;
  letter-spacing: 0.3px;
  line-height: 1;
  min-width: 0;
  min-height: 15px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

%option-checkbox {
  font-family: $font-lato;
  position: relative;

  .checkbox {
    @extend %checkbox;
  }

  label {
    font-size: 12px;
    display: inline-block;
    vertical-align: middle;
    line-height: 1;
    cursor: pointer;
  }

  &.reset-option {
    border-top: solid 1px $fade;
    color: $link;
    cursor: pointer;
    font-weight: bold;
  }
}

%checkbox {
  position: absolute;
  left: 0;
  top: 11px;
  padding-left: 10px;
  z-index: 1;

  + label {
    padding-left: 20px;
    z-index: 2;
    position: relative;
    max-width: max-content;
  }

  input + span {
    margin-right: 0px;
  }
}

%circle-notifications {
  background-color: #da316d;
  border: 0 !important;
  border-radius: 50%;
  color: #fff;
  display: inline-block;
  margin-left: 5px;
  padding: 0 !important;
  font: normal normal 500 13px/15px Lato;
  width: rem(22px);
  height: rem(22px);
  line-height: 21px;
  right: rem(25px);

  @include breakpoint($screen-md) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: rem(20px);
    height: rem(20px);
    line-height: 13px;
    font: normal normal 500 10px/15px Lato;
  }
}

%yellow-button {
  background-color: #F7C131;
  color: #000;
  font-family: $font-concourse-regular;
  font-style: normal;
  line-height: normal;
  font-size: 16px;
  font-weight: 600;
  width: 100%;
  margin-right: 20px;
  border: none;

  @include breakpoint($screen-sm) {
    padding: 17px 65px;
    width: auto;
    font-weight: 600;
    font-size: 18px;
  }
}

%loading {
  transition: opacity 0.5s;
  opacity: 0.5 !important;
  border: none;
  box-shadow: none;
  cursor: progress;
  pointer-events: none;
  position: relative;
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 5px;
    margin-top: -7px;
    width: 15px;
    height: 15px;
    border-radius: 50px;
    border: 2px solid grey;
    border-top-color: #000;
    animation: theloading 1s linear infinite;
  }
}
@keyframes theloading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}