@import url(https://fonts.googleapis.com/css?family=Lato:300,400,700);
@import '~breakpoint-sass/stylesheets/_breakpoint';
@import 'variables';
@import 'utils/mixins';
@import 'utils/typography';
@import './layout/bootstrap-grid.css';
@import './utils/bootstrap-reboot.css';
@import 'utils/entypo';
@import 'utils/utils';
@import 'utils/tooltip';
@import 'utils/checkbox';
@import 'utils/spacing';

#root {
  background-color: $white;
  min-height: 100vh;
}
.filter-container {
  .filter-container--professions {
    order: 1;
  }
  .filter-container--location {
    order: 3;
  }
  .filter-container--specialties {
    order: 2;
  }
  .filter-container--action {
    order: 4;
  }
  @include breakpoint($screen-xs) {
    .filter-container--professions {
      order: 1;
    }
    .filter-container--specialties {
      order: 2;
    }
    .filter-container--location {
      order: 3;
    }
  }
}
.filter-container--professions,
.filter-container--specialties {
  @include breakpoint($screen-md) {
    margin-bottom: 0;
  }
}

.filter-container--professions,
.filter-profession,
.filter-specialties,
.filter-container--specialties,
.filter-location,
.filter-container--location {
  .dropdown__option,
  .specialty__option {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .jobs-count {
    height: 16px;
    background-color: #a2a5ac;
    border-radius: 9px;
    color: $white;
    font-family: $font-lato;
    font-size: 10px;
    padding: 3px 5px;
    display: inline-flex;
    align-items: center;
  }
}

.container-search--controls,
.search-jobs-container {
  .dropdown__menu {
    z-index: 2;
  }
  .option {
    font-family: $font-lato;
    display: flex !important;
    align-items: center;
    justify-content: normal;

    .checkbox {
      vertical-align: middle;
      margin: 0 5px 0 0;
      display: flex;

      input {
        margin: 0;
        position: relative;
      }
    }

    label {
      font-size: 12px;
      cursor: pointer;
      margin: 0;
      line-height: 28px;
    }

    .jobs-count {
      height: 16px;
      background-color: #a2a5ac;
      border-radius: 9px;
      color: $white;
      font-family: $font-lato;
      font-size: 10px;
      padding: 3px 5px;
      display: inline-flex;
      align-items: center;
      margin-left: auto;
    }
  }
}
