@import '~breakpoint-sass/stylesheets/_breakpoint';
@import '../../assets/syles/variables';

.hero-section {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('../../assets/images/pages/home/hero/hero-xls.png');

  @include breakpoint($screen-xs) {
    background-image: url('../../assets/images/pages/home/hero/hero-xs.png');
  }

  @include breakpoint($screen-sm) {
    background-image: url('../../assets/images/pages/home/hero/hero-sm.png');
  }

  @include breakpoint($screen-md) {
    background-image: url('../../assets/images/pages/home/hero/hero-md.png');
  }

  @include breakpoint($screen-lg) {
    background-image: url('../../assets/images/pages/home/hero/hero-lg.png');
  }

  h1 {
    font-family: 'funkydori', sans-serif;
    color: white;
    font-size: 50px;
    font-weight: 400;
    width: 300px;
    margin: 0 auto;

    @include breakpoint($screen-xs) {
      font-size: 85px;
      width: 530px;
    }

    @include breakpoint($screen-sm) {
      font-size: 72px;
      width: auto;
      letter-spacing: 1px;
    }

    @include breakpoint($screen-md) {
      font-size: 84px;
      letter-spacing: 2px;
    }

    @include breakpoint($screen-lg) {
      font-size: 106px;
    }

    @include breakpoint($screen-xl) {
      max-width: 1100px;
      font-size: 108px;
    }
  }

  h3 {
    color: white;
    font-size: 14px;

    @include breakpoint($screen-xs) {
      font-size: 18px;
    }

    @include breakpoint($screen-sm) {
      font-size: 20px;
    }

    @include breakpoint($screen-md) {
      font-size: 20px;
    }

    @include breakpoint($screen-lg) {
      font-size: 24px;
      margin-top: -30px;
    }

    @include breakpoint($screen-xl) {
      font-size: 28px;
      margin-top: -30px;
    }
  }

  .join-btn {
    background: transparent;
    color: $white;
    border: 2px solid white;
    border-radius: 30px;
    font-size: 0.8em;
    letter-spacing: 1px;
    margin: 0 auto;
    padding: 5px 14px;
    font-family: 'Concourse Bold', sans-serif;
  }

  .sign-in-btn {
    color: $white;
    font-size: 0.8em;
    display: flex;
    margin: 0 auto;
    font-family: 'Concourse Bold', sans-serif;
  }

  .mobile-action-btn {
    z-index: 99;
    @include breakpoint($screen-md) {
      display: none !important;
    }
  }

  .sub-title {
    width: 100%;
    max-width: 250px;
    margin: 0 auto;
    text-align: center;

    @include breakpoint($screen-sm) {
      width: 100%;
      max-width: 723px;
      margin-top: -15px;
    }
    @include breakpoint($screen-md) {
      max-width: 871px;
    }
    @include breakpoint($screen-lg) {
      margin-top: 0;
      max-width: 1076px;
    }
    @include breakpoint($screen-xl) {
      max-width: 1100px;
      padding-left: 10px;
    }
  }

  .search-jobs-container {
    position: relative;
    background-color: rgba(255, 255, 255, 0) !important;
    box-shadow: none;
    margin: 0 auto;
    width: 100%;
    label {
      font-size: 14px;
      font-family: $font-lato;
    }
    .type-jobs-container,
    .filter-container {
      background-color: rgba(255, 255, 255, 0.8) !important;
      margin-bottom: 0;
      padding: 10px;
      z-index: 9;
      padding: 10px;
    }

    .type-jobs-container {
      border-top-right-radius: 8px;
      border-top-left-radius: 8px;
      display: flex;
      padding: 0;
      background-color: transparent !important;
      .btn {
        flex: 1;
        background: rgba(255, 255, 255, 0.5);
        border-radius: 0;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        margin-right: 5px;
        font-size: 10px;
        height: 28px;
        .icon {
          width: 13px;
          height: 13px;
          margin-right: 8px;
          fill: inherit !important;
        }
        @include breakpoint($screen-xs) {
          font-size: 13px;
        }
      }
      .btn--active {
        background: rgba(255, 255, 255, 0.8);
        color: inherit !important;
        .icon {
          fill: inherit !important;
          .a {
            fill: inherit !important;
          }
        }
      }
      @include breakpoint($screen-sm) {
        .btn {
          flex: initial;
        }
      }
    }

    .filter-container {
      border-bottom-right-radius: 8px;
      border-bottom-left-radius: 8px;
      @include breakpoint($screen-md) {
        border-top-right-radius: 8px;
      }
      &--action .btn {
        background-color: #24b5cf !important;
        color: #ffffff;
        font-size: 16px;
        padding: 8px 20px;
        border: none;
        width: 100%;
        line-height: inherit;
        span {
          padding-left: 5px;
        }
      }
    }

    .wanda-logo {
      position: absolute;
      top: -100px;
      width: 200px;
      right: -10px;
      @include breakpoint($screen-sm) {
        right: 5%;
      }
      @include breakpoint($screen-md) {
        right: 0;
      }
      img {
        width: 100%;
      }
    }
  }
}

.session-dropdown {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 5px 9px;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  background-color: transparent;
  transition: background-color 3s ease;
  justify-content: end;
  position: relative;

  .session-dropdown-control {
    padding: 10px 3px 12px 3px;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;

    .username {
      font-family: Lato;
      font-size: 16px;
      font-weight: 400;
      line-height: 19.2px;
      text-align: left;
      color: #000000;
      margin: 0  10px;
      text-transform: capitalize;
    }
  }
}

.nav-menu {
  flex: 1 1;
  color: $white;
  font: normal normal bold 14px/17px Lato;
  letter-spacing: 0.56px;

  &-recruiter-login {
    display: flex;
    justify-content: center;
    align-items: center;
    div {
      margin: 0 6px;
      color: #a7a7a7;
    }
    > a {
      color: $black !important;
      font-size: 12px;
      @include breakpoint($screen-lg) {
        font-size: 14px;
      }
      &:hover {
        text-shadow: 0 3px 6px #00000029 !important;
        color: $black !important;
        font-weight: 900;
      }
    }
  }

  .shadow {
    box-shadow: 0px 4px 46px 16px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 0px 4px 46px 16px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 4px 46px 16px rgba(0, 0, 0, 0.75);
  }

  &-desktop {
    display: none;
    padding: 15px 30px 15px 30px;
    background: white !important;

    @include breakpoint($screen-md) {
      display: flex;
      position: fixed;
      width: 100%;
      z-index: 500;
    }

    @include breakpoint($screen-xl) {
      margin: 0 auto;
    }

    &.yellow {
      background: #FAC00A !important;

      .item-active {
        background-color: #fff;
      }
    }
  }

  &-logo {
    > a {
      > svg {
        height: 50px;

        @include breakpoint($screen-sm) {
          height: 42px;
          width: auto;
        }
      }
    }
  }

  &-items {
    flex-grow: 2;
    display: none;
    margin-top: -5px;

    @include breakpoint($screen-md) {
      display: flex;
      align-items: center;
    }

    @include breakpoint($screen-lg) {
      margin-left: 50px;
    }

    &-link {
      padding: 4px;
      margin-top: 10px;
      position: relative;

      @include breakpoint($screen-lg) {
        padding: 4px 10px;
      }

      &:first-child {
        margin-left: 0;
      }

      > a, button {
        text-transform: capitalize;
        letter-spacing: 0.48px;
        color: $black !important;
        position: relative;
        top: -3px;
        font-weight: 700;
        padding: 6px 11px;
        border-radius: 8px;
        font-size: 16px;
        font-family: Lato, serif;
        line-height: 19.2px;
        text-align: left;
        border: none;
        background: transparent;

        @include breakpoint($screen-lg) {
          font-size: 16px;
        }
      }

      > a {
        display: flex;
        align-items: center;
      }

      &:hover {
        > a, button {
          background-color: #fcebbb;
          padding: 6px 11px;
        }
      }
    }

    &-loggedIn {
      justify-content: start;
      margin-left: 50px;

      :global(.nav-menu-items-link) {
        margin: 6px 30px 0;

        @include breakpoint($screen-lg) {
          margin: 6px 50px 0;
        }

        &:first-child {
          margin-left: 0;
        }
      }
    }
  }

  &-signin {
    display: none;

    @include breakpoint($screen-md) {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    > div {
      margin: 0 2px;
      color: #ffffff;

      > a {
        padding: 4px 8px;
        font-family: Lato, serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 19.2px;
        text-align: left;
        letter-spacing: 0px;
        color: $black;
        margin-right: 10px;

        &:not(.menu-active):hover {
          background: #FCEBBB;
          padding: 8px 10px 8px 10px;
          border-radius: 8px;
        }
      }
    }
  }

  &-join {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &-authenticated {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font: normal normal normal 16px/19px Lato;
    color: $white;
    min-width: 60px;
  }

  &-mobile {
    display: flex;
    padding: 25px 15px 0;

    @include breakpoint($screen-sm) {
      padding: 25px 35px 0;
    }

    @include breakpoint($screen-md) {
      display: none;
    }

    &-signin {
      display: none;
      justify-content: center;
      align-items: center;

      @include breakpoint($screen-xs) {
        display: flex;
      }

      @include breakpoint($screen-sm) {
        display: none;
      }

      > div {
        > a {
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-variant-numeric: normal;
          font-variant-east-asian: normal;
          font-weight: bold;
          font-stretch: normal;
          font-size: 18px;
          line-height: 22px;
          text-transform: uppercase;
          color: $white;
        }
      }
    }

    &-hamburger {
      display: flex;
      margin-right: 15px;
      justify-content: center;
      align-items: center;

      @include breakpoint($screen-xs) {
        margin-right: 30px;
      }

      @include breakpoint($screen-md) {
        display: none;
      }
    }

    &-logo {
      flex-grow: 2;
      padding-top: 5px;
      display: flex;
      justify-content: center;

      > a {
        > svg {
          height: 50px;
          width: auto;

          @include breakpoint($screen-sm) {
            height: 50px;
          }

          @include breakpoint($screen-md) {
            height: 55px;
          }

          @include breakpoint($screen-lg) {
            height: 60px;
          }
        }
      }
    }

    &-join {
      display: none;

      @include breakpoint($screen-sm) {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      > button {
        font-style: normal;
        font-variant-ligatures: normal;
        font-variant-caps: normal;
        font-variant-numeric: normal;
        font-variant-east-asian: normal;
        font-weight: bold;
        font-stretch: normal;
        font-size: 11px;
        line-height: 1px;
        font-family: $font-concourse-regular;
        border: 2px solid $white;
        border-radius: 18px;
        background: transparent;
        text-transform: uppercase;
        color: $white;
        padding: 8px 11px;
        max-height: 26px;
      }
    }

    .hamburger {
      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: center;

      &-container {
        cursor: pointer;
      }

      &-bar-icon {
        width: 22px;
        height: 3px;
        margin: 6px 0;
        transition: 0.4s;
        background: white;
      }

      &-change {
        > div {
          &:first-child {
            transform: translate(0, 7px) rotate(-45deg);
          }

          &:nth-child(2) {
            opacity: 0;
          }

          &:last-child {
            transform: translate(0, -11px) rotate(45deg);
          }
        }
      }
    }
  }
}

#join-for-free-btn {
  border-radius: 25px;
  color: #ffffff;
  padding: 12px;
  background: #095f7a;
  background: linear-gradient(90deg, #095f7a 0%, #42a8c8 100%);
  border: none;
  font-family: Lato, serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 16.59px;
  text-align: center;


  &:hover {
    background: linear-gradient(272.81deg, #17CFEE -3.49%, #0092C0 84.65%);
  }
}

#get-app-btn {
  border-radius: 25px;
  color: #ffffff;
  padding: 12px;
  background: #095f7a;
  background: linear-gradient(90deg, #095f7a 0%, #42a8c8 100%);
  border: none;
  font-family: Lato, serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 16.59px;
  text-align: center;
  margin-left: 15px;
  cursor: pointer;

  &:hover {
    background: linear-gradient(272.81deg, #17CFEE -3.49%, #0092C0 84.65%);
  }
}

.settings-menu {
  position: absolute;
  z-index: 1000;
  display: none;
  padding: 0;
  margin: 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #f4f4f4;
  background-clip: padding-box;
  top: 60px;
  left: 9px;
  border-radius: 10px;
  width: 160px;
  border: 0;
  box-shadow: -1px 6px 27px -3px rgba(0, 0, 0, 0.21);
  -webkit-box-shadow: -1px 6px 27px -3px rgba(0, 0, 0, 0.21);
  -moz-box-shadow: -1px 6px 27px -3px rgba(0, 0, 0, 0.21);

  &-opened {
    display: block;
  }

  li {
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    padding: 15px !important;

    &:hover {
      background: #e3e3e3;
    }

    > a {
      font-size: 1em;
      font-family: 'Lato', sans-serif;
      line-height: 1;
      color: #303030;
      text-transform: none;
      padding: 0 !important;
    }
  }
}

.circle-notification {
  background-color: $purple-featured;
  border: 0 !important;
  border-radius: 50%;
  color: $white;
  line-height: 150%;
  text-align: center;
  width: 22px;
  height: 22px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 !important;
  font: normal normal 900 10px/15px Lato;
  margin-left: 5px;
}

.header-fixed {
  position: fixed;
  width: 100%;
  z-index: 1049;
  background-color: $brand-nurse !important;
  padding: 19.5px 30px;
  max-width: 100%;
}

.resources-menu-react {
  position: absolute;
  background: white;
  list-style: none;
  padding: 0;
  left: 8px;
  border-radius: 10px;
  cursor: pointer;
  top: 40px;
  box-shadow: 0px 10px 19px -7px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 10px 19px -7px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 10px 19px -7px rgba(0, 0, 0, 0.75);
  min-width: 160px;

  li:first-child a:hover {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  li:last-child a:hover {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
}

.resources-tab {
  font-family: Lato, serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  text-align: left;

  a, button {
    padding: 12px 35px 12px 13px !important;
    border: 0;
    color: #000000 !important;
    display: block;

    &:hover {
      color: #000000;
    }
  }

  &:hover {
    background-color: #EDEDED;
  }

  &:first-child {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }

  &:last-child {
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }
}

.resource-active {
  background-color: #fcebbb;
}

.item-active {
  background-color: #F8C04D !important;
}

