@import "../../../assets/syles/variables";

.profession__control {
    width: 100%;
    background-color: $white;
    border-color: hsl(0, 0%, 80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    height: 38px;
    font-size: 13px;

    .profession__value-container {
        .profession__placeholder {
            color: #A2A5AC;
            margin-left: 2px;
            margin-right: 2px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            box-sizing: border-box;
        }

    }

    .profession__indicator-separator {
        display: none;
    }

    .profession__dropdown-indicator {
        margin-top: 6px;
    }
}

.profession__menu {
    z-index: 199 !important;
}
