@import"~breakpoint-sass/stylesheets/_breakpoint";
@import "../../assets/syles/variables";
@import "../../assets/syles/utils/mixins";

.location__control {
    border-color: #cccccc;
    box-shadow: none;

    &.location__control--is-focused {
        border-color: $filter-placeholder-color !important;
        box-shadow: 0 0 0 1px $filter-placeholder-color;
    }

    .a {
        fill: rgb(168, 168, 168);
    }

    .location__value-container {
        margin-left: 8px;

        @include breakpoint($screen-sm) {
            margin-left: -30px;
        }

        @include breakpoint($screen-md) {
            margin-left: 0;
        }
    }

    .location__indicators {
        .location__loading-indicator {
            position: absolute;
            right: 0;
        }
    }

    .location__multi-value__remove {
        &:hover {
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
        }
    }
}

.location__menu {
    z-index: 199 !important;

    &-list {
        .location__option {
            label {
                padding: 3px;
                margin-bottom: 0;
            }

            label input {
                margin-top: 2px;
                margin-right: 5px;
                cursor: pointer;
            }

            label:last-child {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                width: 85%;
            }
        }
    }
}
