@import '~breakpoint-sass/stylesheets/_breakpoint';
@import './src/assets/syles/variables';
@import './src/assets/syles/utils/mixins';
@import './src/assets/syles/utils/extends';

.container-field {
  position: relative;
}

#signUpQuestionsModal{
  z-index: 10000;
  .loading-spinner{
    position: fixed;
    z-index: 9999;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .nav-menu-logo {
    height: 45px;
  }
  .back-button{
    position: absolute;
    top: 15px;
    left: 15px;
    width: 35px;
    height: 35px;
    cursor: pointer;
  }
  .questions-form {
    padding: 0 15px;
  
    @include breakpoint($screen-sm) {
      padding: 0;
    }
    .specialty.input-dropdown__value-container {
      margin-left: 11px;
    }
    .label {
      color: $night;
      font-family: $font-lato;
      font-size: 13px !important;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
      margin: 0;
      text-align: left;
      padding-left: 0;
      margin-top: 10px;
  
      @include breakpoint($screen-sm) {
        font-size: 14px;
        line-height: 24px;
        margin-top: 10px;
      }
  
      &-exp {
        white-space: nowrap;
  
        @include breakpoint($screen-sm) {
          width: 100%;
          overflow: visible;
        }
      }
    }
  
    .input-text, .profession.dropdown__control, .input-dropdown__control {
      border-radius: 8px;
      border: 1px solid #d0d5dd;
      /* Shadow/xs */
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      height: 35px;
  
      &.years-exp {
        width: calc(100% - 30px);
  
        @include breakpoint($screen-sm) {
          width: 100%;
        }
      }
    }
    .input-text-container {
      width: 100%;
      display: block;
  
      @include breakpoint($screen-sm) {
        width: 100%;
      }
    }
    .input-with-error, .input-with-error__control { 
      border-color: #f43d4b !important;
    }
    .input-dropdown__control {
      border-radius: 8px;
      border: 1px solid #d0d5dd;
      /* Shadow/xs */
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    }
  
    .profession,
    .specialty {
      .__control {
        border-radius: 8px;
        max-height: 38px;
        box-shadow: none;
        border: 1px solid #d0d5dd !important;
  
        .__indicators {
          > div {
            border: none !important;
          }
        }
      }
  
      .dropdown__control {
        border-radius: 8px;
        max-height: 38px;
      }
  
      .dropdown__single-value--is-disabled {
        color: #969696;
      }
    }
  
    .specialty.__value-container {
      padding-left: 8px;
      position: relative;
      top: 2px;
    }
  
    .profession.dropdown__indicator-separator {
      display: none;
    }
  
    .legend {
      color: $brand-agency-alt;
      font-family: $font-lato;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 15px;
      margin-bottom: 30px;
      position: absolute;
  
      @include breakpoint($screen-sm) {
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 20px;
      }
    }
  
    .add-specialties-btn {
      color: #06759a;
      font-family: $font-concourse-regular;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin: 25px 0;
  
      @include breakpoint($screen-sm) {
        font-size: 16px;
        font-weight: 600;
        line-height: normal;
      }
  
      svg {
        width: 20px;
        height: 20px;
      }
    }
  
    .submit-btn {
      @extend %yellow-button;
      background-color: #f7c131 !important;
      font-size: 14px !important;
  
      &.disabled {
        color: #a89769 !important;
      }
  
      &.loading {
        @extend %loading;
  
        &:after {
          right: 15px;
          margin-top: -9px;
          width: 20px;
          height: 20px;
          left: auto;
        }
      }
  
      &:hover,
      &:active,
      &:focus {
        @extend %yellow-button;
        border: none;
        box-shadow: none;
      }
    }
  
    .delete-specialty-icon {
      position: absolute;
      height: 17px;
      right: 14px;
      top: 9px;
      cursor: pointer;
  
      @include breakpoint($screen-sm) {
        right: -15px;
        top: 54px;
      }
    }
    .invalid-message{
      text-align: left;
    }
    .profession.dropdown__menu {
      text-align: left;
    }
  }

  .checkbox-authorized {
    padding-right: 0;
    text-align: left;
    display: flex;

    input + span {
      width: 18px !important;
      height: 18px !important;

      @include breakpoint($screen-md) {
        width: 24px !important;
        height: 24px !important;
      }
    }

    input + span::after {
      width: 10px !important;
      height: 10px !important;
      margin: 4px 4px !important;

      @include breakpoint($screen-md) {
        width: 15px !important;
        height: 15px !important;
        margin: 5px 5px !important;
      }
    }

    .legend {
      color: #303030;
      font-family: "Lato", sans-serif;
      font-size: 14px;
      font-weight: normal !important;
      font-style: normal;
      margin-left: 5px;

      @include breakpoint($screen-md) {
        font-size: 15px;
      }
    }
  }
  
  .modal-content.questionary {

    @include breakpoint($screen-md) {
      margin: 25px auto 0;
      overflow: auto !important;
    }
  
    .container-btn-back {
      font-size:0.9rem;
      font-weight:bold;
      letter-spacing:0.1rem;
  
      @include breakpoint($screen-mobile-only) {
        top:0;
        left:0;
        width:100vw;
        height:50px;
        margin:0;
        padding:0;
        position:fixed;
        background-color:#FAC00A;
        z-index:3333;
  
        a {
          width:50px;
          height:50px;
          padding:10px;
          float:left;
          display:inline-block;
  
          .glyphicon {
            top:5px;
            color:#FFFFFF;
            font-size:20px;
            font-weight:bold;
            line-height:24px;
            vertical-align:middle;
          }
  
          & + .logo {
            margin:0 50px;
            img {
              max-width:50%;
              margin-top:10px;
              @include breakpoint($screen-sm) {
                height: 50px;
                margin-top:0;
              }
            }
          }
        }
      }
    }
  
    .container-buttons {
      width: 490px;
      padding: 0 40px 0;
      background-color: $white;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      margin-top: 30px;
  
      .btn-primary {
        background: $night;
        border: $night;
        border-radius: 2.5rem;
      }
  
      @include breakpoint($screen-mobile-only) {
        width: 100%;
        margin: 30px 0 0;
        padding: 10px 25px;
        bottom: 0;
      }
    }
  
    .modal-body {
      height: 100% !important;
      padding: 60px 5px 0 !important;
      overflow-y: hidden;
      max-height: unset;
      @include breakpoint($screen-mobile-only) {
        justify-content: flex-start;
        overflow-y: auto;
      }
      @include breakpoint($screen-md) {
        min-height: 150px;
        padding: 0px 35px !important;
        position:relative;
        border-radius: 25px !important;
      }
  
      .tabs {
        display: block !important;
  
        @include breakpoint($screen-mobile-only) {
          padding: 20px 0 0 !important;
        }
  
        @include breakpoint($screen-md) {
          padding-top: 20px !important;
        }
      }
  
      .title {
        color:#303030;
        font-size:24px;
        font-style:normal;
        font-family:Lato;
        font-weight:normal;
        font-stretch:normal;
        line-height:normal;
        letter-spacing:-0.1px;
        @include breakpoint($screen-mobile-only) {
          font-size:20px;
          letter-spacing:0px;
        }
      }
  
      .question {
        color:#303030;
        font-size:18px;
        font-style:normal;
        font-family:Lato;
        font-weight:bold;
        font-stretch:normal;
        line-height:normal;
        letter-spacing:-0.1px;
        @include breakpoint($screen-mobile-only) {
          font-size:15px;
          letter-spacing:-0.8px;
        }
      }
  
      .fine-print {
        margin-top:10px;
        color:#303030;
        font-size:14px;
        font-style:normal;
        font-family:Lato;
        font-weight:300;
        font-stretch:normal;
        line-height:normal;
        letter-spacing:0px;
        @include breakpoint($screen-mobile-only) {
          margin-top:15px;
          font-size:14px;
          letter-spacing:normal;
        }
      }
  
      .form-control {
        @include breakpoint($screen-mobile-only) {
          height:37px;
          font-size:14px;
          line-height:16px;
  
          @media screen and (-webkit-min-device-pixel-ratio:0) {
            width: 100%;
            height:42.29px !important;
            margin-right:-10px;
            font-size:16px !important;
            line-height:18.29px !important;
            transform:scale(0.875);
            transform-origin:left top;
          }
        }
      }
  
      .container-title {
        padding:0 40px;
        @include breakpoint($screen-mobile-only) {
          padding:0;
        }
      }
  
      .container-steps {
        label {
          width: 100%;
          margin-top:15px;
  
          @include breakpoint($screen-mobile-only) {
            width: 270px;
            margin: 15px auto 0;
            font-size: 14px;
          }
  
          .asterisk {
            color: #C92A3A;
          }
        }
  
        .step-5 {
          .tabs.text-left {
            @include breakpoint($screen-mobile-only) {
              display: flex !important;
              flex-direction: column;
            }
          }
  
          .form-control {
            width: 100%;
  
            @include breakpoint($screen-mobile-only) {
              width:270px;
              margin:0 auto;
              @media screen and (-webkit-min-device-pixel-ratio:0) {
                width: 308.57px;
              }
            }
          }
        }
  
        .step-1 {
          label {
            width:298px;
            height:55px;
            margin:15px auto 0;
            padding:17px 0;
            color:#303030;
            cursor:pointer;
            font-size:16.8px;
            font-family:Lato;
            font-style:normal;
            font-weight:normal;
            font-stretch:normal;
            line-height:normal;
            letter-spacing:-0.1px;
            border:1.2px solid #303030;
            @include breakpoint($screen-mobile-only) {
              width:248px;
              height:46px;
              padding:15px 0;
              font-size:14px;
              border:1px solid #303030;
            }
  
            &.active {
              color:#FFFFFF;
              font-weight:bold;
              border:1.2px solid #F7C131;
              background-color:#F7C131;
              @include breakpoint($screen-mobile-only) {
                border:1px solid #F7C131;
              }
            }
          }
        }
  
        .step-2 {
          ul {
            width:460px;
            height:215px;
            margin:0 auto;
            padding:4px 0px;
            list-style:none;
            border:1px solid #DFDFDF;
            border-top:0px none;
            overflow-x:hidden;
            overflow-y:scroll;
            @include breakpoint($screen-mobile-only) {
              width:273px;
              height:calc(100vh - 400px);
              padding:15px 0 0;
              border:0px none;
            }
  
            li {
              height:32px;
              @include breakpoint($screen-lg) {
                padding-left:10px;
              }
  
              label {
                height:22px;
                margin:3px 0;
                cursor:pointer;
                line-height:22px;
              }
  
              input {
                margin:0px;
  
                & + span {
                  width:22px;
                  height:22px;
  
                  &::after {
                    width:22px;
                    height:22px;
                    margin:0px;
                    transform:scale(0.55);
                  }
  
                  & + span {
                    color:#303030;
                    font-size:14px;
                    font-style:normal;
                    font-family:Lato;
                    font-weight:normal;
                    font-stretch:normal;
                    letter-spacing:-0.1px;
                    line-height:22px;
                    @include breakpoint($screen-mobile-only) {
                      font-size:12px;
                    }
                  }
                }
              }
            }
          }
  
          .tabs {
            padding-top:25px !important;
          }
  
          .input-search {
            width:460px;
            margin:0 auto;
            color:#B2B2B2;
            font-size:14px;
            font-weight:600;
            font-family:Lato;
            font-style:normal;
            font-stretch:normal;
            line-height:normal;
            letter-spacing:0px;
            @include breakpoint($screen-mobile-only) {
              width:273px;
              height:37px;
              @media screen and (-webkit-min-device-pixel-ratio:0) {
                width:312px !important;
              }
            }
          }
        }
  
        .step-3 {
          ul {
            width:460px;
            height:215px;
            margin:0 auto;
            padding:4px 0 0;
            list-style:none;
            border:0px none;
            overflow-x:hidden;
            overflow-y:auto;
  
            @include breakpoint($screen-mobile-only) {
              width:273px;
              max-height: 200px;
              padding:0;
              border:0px none;
            }
  
            li {
              height: auto;
              line-height:50px;
              @include breakpoint($screen-mobile-only) {
                line-height:37px;
              }
  
              label {
                margin:3px 0;
                cursor:pointer;
                width: 100%;
              }
  
              input {
                width:80px;
                padding:10px;
                margin:0 10px 0;
                float:right;
                @include breakpoint($screen-mobile-only) {
                  width:50px;
                  height:37px;
                  @media screen and (-webkit-min-device-pixel-ratio:0) {
                    width:57.14px !important;
                    margin-bottom:-4px !important;
                  }
                }
  
                &.error {
                  border-color: #C92A3A;
                }
              }
  
              .help-block {
                color: #C92A3A;
                font-size: 80%;
                font-weight: normal;
                margin-top: 2px;
                text-align: right;
                line-height: 1;
                display: none;
                margin-right: 11px;
              }
            }
          }
        }
  
        .step-4 {
          ul {
            width:460px;
            height:215px;
            margin:0 auto;
            padding:4px 0px;
            list-style:none;
            border:1px solid #DFDFDF;
            border-top:0px none;
            overflow-x:hidden;
            overflow-y:scroll;
            @include breakpoint($screen-mobile-only) {
              width:273px;
              height:calc(100vh - 400px);
              padding:15px 0 0;
              border:0px none;
            }
  
            li {
              height:32px;
              @include breakpoint($screen-lg) {
                padding-left:10px;
              }
  
              label {
                height:22px;
                margin:3px 0;
                cursor:pointer;
                line-height:22px;
              }
  
              input {
                margin:0px;
  
                & + span {
                  width:22px;
                  height:22px;
  
                  &::after {
                    width:22px;
                    height:22px;
                    margin:0px;
                    transform:scale(0.55);
                  }
  
                  & + span {
                    color:#303030;
                    font-size:14px;
                    font-style:normal;
                    font-family:Lato;
                    font-weight:normal;
                    font-stretch:normal;
                    letter-spacing:-0.1px;
                    line-height:22px;
                    @include breakpoint($screen-mobile-only) {
                      font-size:12px;
                    }
                  }
                }
              }
            }
          }
  
          .tabs {
            padding-top:25px !important;
          }
  
          .input-search {
            width:460px;
            margin:0 auto;
            color:#B2B2B2;
            font-size:14px;
            font-weight:600;
            font-family:Lato;
            font-style:normal;
            font-stretch:normal;
            line-height:normal;
            letter-spacing:0px;
            @include breakpoint($screen-mobile-only) {
              width:273px;
              height:37px;
              @media screen and (-webkit-min-device-pixel-ratio:0) {
                width:312px !important;
              }
            }
          }
        }
      }
  
      .container-complete {
        .text {
          font-size:20px;
          font-style:normal;
          font-family:Lato;
          font-weight:normal;
          font-stretch:normal;
          line-height:normal;
          letter-spacing:0px;
          @include breakpoint($screen-mobile-only) {
            font-size:16px;
          }
        }
        .title {
          margin:20px 0;
          font-size:24px;
          font-style:normal;
          font-family:Lato;
          font-weight:bold;
          font-stretch:normal;
          line-height:normal;
          letter-spacing:-0.1px;
          @include breakpoint($screen-mobile-only) {
            margin:30px 0 15px;
            font-size:21px;
            letter-spacing:0px;
          }
        }
        .wanda-mail {
          width:260px;
          height:207px;
          @include breakpoint($screen-mobile-only) {
            width:284px;
            height:194px;
          }
        }
      }
  
      .progress-circle {
        width:46.5px;
        height:46.5px;
        display:inline-block;
        border:4.5px solid #DFDFDF;
        border-radius:24px;
        background-color:#FFFFFF;
        @include breakpoint($screen-mobile-only) {
          width:31px;
          height:31px;
          border:3px solid #DFDFDF;
        }
  
        &.done, &[data-completed="true"] {
          &:not(.active) {
            border: 4.5px solid $brand-agency;
            background-color: $white;
            @include breakpoint($screen-mobile-only) {
              border: 3px solid $brand-agency;
            }
  
            .step {
              visibility: hidden;
  
              &::after {
                top: 1px;
                left: -4px;
                width: 19.5px;
                height: 13.5px;
                content: ' ';
                position: relative;
                display: inline-block;
                visibility: visible;
                background: url(data:image/svg+xml;charset=utf-8;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMy4wMSA4Ljk3Ij48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6IzAwYjVjZjt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmNoZWNrbWFyazwvdGl0bGU+PGcgaWQ9IkxheWVyXzIiIGRhdGEtbmFtZT0iTGF5ZXIgMiI+PGcgaWQ9IkxheWVyXzEtMiIgZGF0YS1uYW1lPSJMYXllciAxIj48cGF0aCBpZD0iY2hlY2ttYXJrIiBjbGFzcz0iY2xzLTEiIGQ9Ik0xMi42NywxLjk0LDUuODQsOC42NmExLjczLDEuNzMsMCwwLDEtMiwwTC4zNCw1LjE3QTEuMTQsMS4xNCwwLDAsMSwuMTksMy43M2ExLDEsMCwwLDEsLjE1LS4xN0ExLjE3LDEuMTcsMCwwLDEsMiwzLjU2TDQuODYsNi40LDExLC4zM2ExLjE3LDEuMTcsMCwwLDEsMS42NCwwLDEuMTMsMS4xMywwLDAsMSwuMTUsMS40NEExLDEsMCwwLDEsMTIuNjcsMS45NFoiLz48L2c+PC9nPjwvc3ZnPg==) no-repeat center;
                @include breakpoint($screen-mobile-only) {
                  top: 0;
                  left: -3px;
                  width: 13px;
                  height: 9px;
                }
              }
            }
  
            & + .progress-bar {
              background-color: #00B5CF;
            }
          }
        }
  
        &.active {
          border:4.5px solid #00B5CF;
          background-color:#00B5CF;
          @include breakpoint($screen-mobile-only) {
            border:3px solid #00B5CF;
          }
  
          .step {
            color:#FFFFFF;
          }
        }
  
        & .step {
          width:38px;
          height:38px;
          display:block;
          color:#CCCCCC;
          font-size:18px;
          font-style:normal;
          font-family:Lato;
          font-weight:bold;
          font-stretch:normal;
          line-height:36px;
          letter-spacing:-0.1px;
          vertical-align:middle;
          @include breakpoint($screen-mobile-only) {
            width:25px;
            height:25px;
            font-size:12px;
            line-height:26px;
          }
        }
  
        & + .progress-bar {
          top:-4px;
          width:46.5px;
          height:4.5px;
          float:initial;
          display:inline-block;
          position:relative;
          box-shadow:none;
          background-color:#DFDFDF;
          @include breakpoint($screen-mobile-only) {
            top:-3px;
            width:30px;
            height:3px;
          }
        }
      }
    }
  
    .js-observer-questionary-back-container {
      position: absolute;
      top: 0;
      left: 20px;
      a {
        color: $silver;
      }
    }
  }
  .code-container{
    width: 100%;
    @include breakpoint($screen-md) {
     width: 500px;
    }
    margin: 0 auto;
  }
  h2 {
    color: $night;
    text-align: center;
    font-family: $font-lato;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
  }

  h3 {
    text-align: center;
    font-family: $font-lato;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 93.75% */
  }

  .btn-resend {
    color: #000 !important;
    border-radius: 2.5rem;
    background: #FAC00A !important;
    margin-top: 1rem;
    box-shadow: none;

    &:hover  {
      color: #343434 !important;
      border-radius: 2.5rem;
      background: #FFF2CD !important;
      font-weight: 700;
      box-shadow: none;
    }

    &:disabled  {
      border-radius: 2.5rem;
      box-shadow: none;
      border: 1.5px solid #EDEDED !important;
      background: #fde59c !important;
      color: #9D9EA2 !important;
    }

    &-disabled {
      border-radius: 5px;
      border: 1.5px solid #EDEDED !important;
      background: #fde59c !important;
      color: #9D9EA2 !important;

      &:hover  {
        border-radius: 5px;
        border: 1.5px solid #EDEDED !important;
        background: #fde59c !important;
        color: #9D9EA2 !important;
      }
    }
  }

  .resend-legend {
    color: #9D9EA2;
    font-family: Lato, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 7px;
    text-align: center;
  }
  .react-code-input {
    input{
      @media (max-width: 400px) {
        width: 35px !important;
        height: 38px !important;
        margin: 6px !important;
        font-size: 20px !important;
      }
      @media(min-width: 401px) and (max-width: 767.99999px) {
        width: 40px !important;
        height: 48px !important;
        margin: 7px !important;
      }
      font-size: 25px !important;
      padding-left: 10px !important;
    
      font-family: monospace;
      border-radius: 6px;
      border: 1px solid lightgrey;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 10px 0px;
      margin: 4px;
      width: 36px;
      height: 42px;
      font-size: 32px;
      box-sizing: border-box;
      color: black;
      background-color: white;
    }
    &.error input{
      border-color: red;
      border-width: .15rem;
    }
  }
}

