@import '~breakpoint-sass/stylesheets/_breakpoint';
@import '../../assets/syles/variables';
@import '../../assets/syles/utils/mixins';

.partners-section {
  background: $sand-sec;
  padding: 30px 15px;
  min-height: 610px;

  @include breakpoint($screen-sm) {
    min-height: inherit;
  }

  @include breakpoint($screen-sm $screen-md) {
    padding: 30px 30px;
  }

  @include breakpoint($screen-md) {
    padding: 30px 87px;
  }

  .title-container {
    margin-bottom: 30px;

    h5 {
      margin-top: 0;
      font-family: 'Concourse Regular', sans-serif;
      font-weight: 700;
      text-transform: uppercase;
      color: $silver;
      font-size: 14px;

      @include breakpoint($screen-md) {
        font-size: 18px;
      }
    }
  }

  .partners-images {
    .first.row,
    .second.row {
      @include breakpoint($screen-sm) {
        margin-bottom: 30px;
      }
    }

    .second {
      div {
        text-align: center;

        @include breakpoint($screen-sm) {
          .text-center {
            text-align: center;
          }

          .text-start {
            text-align: left;
          }

          .text-end {
            text-align: right;
          }
        }
      }
    }

    img {
      height: auto;
    }

    .ehealth {
      img {
        width: 97px;
        @include breakpoint($screen-sm) {
          width: 110px;
        }
        @include breakpoint($screen-md) {
          width: 156px;
        }
      }
    }

    .nrpc {
      img {
        width: 107px;
        @include breakpoint($screen-sm) {
          width: 135px;
          margin-top: -15px;
        }
        @include breakpoint($screen-md) {
          width: 146px;
        }
      }
    }

    .furnished {
      img {
        width: 84px;
        @include breakpoint($screen-sm) {
          width: 94px;
        }
        @include breakpoint($screen-md) {
          width: 114px;
        }
      }
    }

    .cortfurniture {
      img {
        width: 109px;
        @include breakpoint($screen-sm) {
          width: 119px;
        }
        @include breakpoint($screen-md) {
          width: 113px;
        }
      }
    }

    .nursing-ce {
      img {
        width: 112px;
        @include breakpoint($screen-sm) {
          width: 137px;
        }
        @include breakpoint($screen-md) {
          width: 178px;
        }
      }
    }

    .staffingreferrals {
      @include breakpoint($screen-sm) {
        padding: 0 15px;
      }

      img {
        width: 170px;
      }
    }

    .textus {
      img {
        width: 112px;
        @include breakpoint($screen-sm) {
          width: 158px;
        }
      }
    }

    .sense {
      img {
        width: 127px;
        @include breakpoint($screen-sm) {
          width: 188px;
        }
      }
    }

    .partner-image {
      margin-bottom: 30px;
      padding: 5px;
      width: 100%;
      @include breakpoint($screen-sm) {
        margin-bottom: 0;
        padding: 15px;
        vertical-align: top !important;
        margin-left: 0;
        width: auto;
      }
      @include breakpoint($screen-md) {
        margin-left: 25px;
      }
      @include breakpoint($screen-lg) {
        margin-left: 65px;
      }
      &:first-child {
        margin-left: 0;
      }
    }
  }
}
