@import '../../assets/syles/variables';
@import '~breakpoint-sass/stylesheets/_breakpoint';

.blogs-section {
  max-width: 1050px;
  margin: 0 auto;

  img {
    height: 100%;
  }

  .home-carousel-item {
    height: 350px;
    width: 240px !important;
    overflow: hidden;

    @include breakpoint($screen-xs) {
      height: 430px;
      width: 300px !important;
    }
    @include breakpoint($screen-sm) {
      width: 180px !important;
    }
    @include breakpoint($screen-md) {
      width: 220px !important;
      height: 430px;
    }
    @include breakpoint($screen-lg) {
      width: 245px !important;
    }
  }

  .overlay {
    opacity: 0.3;
    background-color: $black;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
  }

  .item-container {
    background-position: center;
    cursor: pointer;
    height: 100%;
    display: block;
    &:after {
      content: "";
      width: 100%;
      display: block;
      height: 100%;
      background: black;
      opacity: 0.3;
      z-index: 1;
    }
  }

  .info {
    bottom: 10px;
    position: absolute;
    padding-left: 20px;
    z-index: 2;
    h3 {
      font-size: 1.5em;
      width: 153px;
      @include breakpoint($screen-xs) {
        font-size: 1.8em;
        width: 190px;
      }
      @include breakpoint($screen-sm) {
        font-size: 1.5em;
        width: 138px;
      }
      @include breakpoint($screen-md) {
        width: 150px;
      }
      @include breakpoint($screen-xl) {
        width: 170px;
      }
    }
    span {
      font-size: 1em;
    }
    h3,
    span {
      color: white;
    }
  }

  .slick-track {
    display: flex;
  }

  .slick-track .slick-slide {
    display: flex;
    height: auto;
    align-items: center;
    justify-content: center;
  }

  .carousel-prev,
  .carousel-next {
    width: 17px;
    bottom: -75px;
    top: inherit;
  }

  .carousel-prev {
    left: 46%;
  }

  .carousel-next {
    right: 46%;
  }
}
