@import '~breakpoint-sass/stylesheets/_breakpoint';
@import '../../assets/syles/variables';
@import '../../assets/syles/utils/mixins';

.card .card-button {
  position: absolute;
  top: 43%;
  box-shadow: none;
  border-radius: 5px;
  width: 36px;
  height: 36px;
  background-color: transparent;
}

.card .card-button.right {
  right: 0;
}
.react-stacked-center-carousel {
  padding: 20px 0;
}

.react-stacked-center-carousel-slide-0 .card-card {
  cursor: default;
}

.react-stacked-center-carousel-slide-0 .card-card:hover {
  transform: none;
}

.react-stacked-center-carousel-slide-0 .card-overlay {
  background-color: #ffffffa8 !important;
}

.react-stacked-center-carousel-slide-0 .card-card .cover {
  transition: opacity 300ms ease, z-index 0ms 300ms;
}

.fill {
  width: 100%;
  height: 100%;
}

.card-card {
  transition: all 300ms ease;
  cursor: pointer;
  width: 100%;
  border-radius: 15px;
  height: 230px;
  position: relative;
  background-color: #ffffff;
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2), 0 0 4px rgba(0, 0, 0, 0.05);
  font: normal normal normal 12px/18px Lato;

  @include breakpoint($screen-sm) {
    font: normal normal normal 14px/20px Lato;
  }

  @include breakpoint($screen-md) {
    height: 250px;
    font: normal normal normal 16px/24px Lato;
  }

  &:hover {
    transform: scale(1.05);

    .card-overlay {
      background-color: #ffffffa8;
    }
  }

  .card-overlay {
    user-select: none;
    position: absolute;
    background-color: #ffffffa8;
    transition: all 300ms ease;
    border-radius: 15px;
  }

  .cover-image {
    object-fit: cover;
  }

  .cover {
    position: absolute;
    transition: opacity 300ms ease;
  }

  .cover.on {
    opacity: 1;
    z-index: 1;
  }

  .cover.off {
    opacity: 0;
    z-index: -1;
  }

  .detail {
    display: flex;
    flex-direction: column;
    position: relative;
  }

  .video {
    width: 20%;
  }

  .description {
    display: flex;
    flex-direction: column;
    margin: 10px;
    padding: 25px 25px 0;

    p {
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
    }
  }

  .author {
    &-container {
      position: absolute;
      bottom: 0;
      left: 0;
      display: flex;
      padding: 0 25px 25px;
      width: 70%;
      flex-direction: column;

      @include breakpoint($screen-sm) {
        width: 100%;
        flex-direction: row;
      }
    }

    &-name {
      font-weight: bold;
      margin-right: 5px;

    }
  }

  .quote-icon {
    position: absolute;
    bottom: 35px;
    right: 100px;

    @include breakpoint($screen-sm) {
      bottom: 20px;
      right: 25px;
    }
  }
}
