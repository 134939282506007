@import"~breakpoint-sass/stylesheets/_breakpoint";
@import "../../assets/syles/variables";
@import "../../assets/syles/utils/mixins";

.custom-datePicker {
  // custom input
  .react-datepicker-wrapper {
    width: 100%;
    display: flex;

    .react-datepicker__input-container {

        .react-datepicker__close-icon {
            &:hover {
                &:after {
                    background-color: #FFBDAD;
                    color: #DE350B;
                }
            }
        }

      .custom-input-field {
        font-size: 13px;
        color: #333333;
        width: 100%;
        height: 35px;
        display: flex;
        align-items: center;
        position: relative;
        background-color: #ffffff;
        border-bottom-left-radius: 4px;
        border-top-left-radius: 4px;
        border: 1px solid hsl(0, 0%, 80%);
        box-shadow: none;

        &.placeholder {
          color: $filter-placeholder-color;
          cursor: default;
        }

        svg {
            width: 15px;
            height: 15px;
            margin-left: 10px;
            margin-right: 13px;

            .a {
                fill: $brand-primary;
            }
        }

        &.bg-gray-200 {
          background-color: #f7f7f7;
        }
      }

      .react-datepicker__close-icon::after {
        background-color: $fade;
      }
    }
  }

  // popper
  .react-datepicker-popper[data-placement^='bottom'] {
    margin-top: 3px;
  }

  .react-datepicker-popper[data-placement^='top'] {
    margin: 0;
  }

  .react-datepicker {
    font-family: inherit;
    font-size: 10px;
    background-color: #ffffff;
    color: $brand-primary;
    border: 1px solid $fade;
    border-radius: 0;

    .react-datepicker__triangle {
      display: none;
    }

    // Nav btn
    .react-datepicker__navigation {
      background: none;
      line-height: 1.7rem;
      text-align: center;
      cursor: pointer;
      position: absolute;
      top: 18px;
      padding: 0;
      border: 0;
      z-index: 1;
      height: 10px;
      width: 8px;
      display: flex;
      background-repeat: no-repeat;
      background-position: center center;
      text-indent: -999em;
      font-size: 1px;
      overflow: hidden;
      outline: 0;
      box-shadow: none;

      &--previous {
        background-image: url('../../assets/images/icons/arrowCalendarPrevious.svg');
        left: 19px;

        .react-datepicker__navigation-icon--previous::before {
            display: none;
        }
      }

      &--next {
        background-image: url('../../assets/images/icons/arrowCalendarNext.svg');
        right: 19px;

        .react-datepicker__navigation-icon--next::before {
            display: none;
        }

      }
    }

    .react-datepicker__month-container {
      padding: 5px;
    }

    // Header
    .react-datepicker__header {
      text-align: center;
      background-color: transparent;
      border-bottom: 0;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      padding-top: 10px;
      position: relative;

      .react-datepicker__current-month {
        font-size: 12px;
        color: $brand-primary;
        font-weight: 400;
      }

      .react-datepicker__day-names {
        margin-top: 5px;
      }

      // Day
      .react-datepicker__day-name,
      .react-datepicker__day,
      .react-datepicker__time-name {
        text-transform: uppercase;
        font-size: 9px;
        color: #555555;
        display: inline-block;
        width: 24px;
        line-height: 24px;
        text-align: center;
        margin: 2px;
      }
    }

    // month
    .react-datepicker__month {
      margin: 0 4px;

      .react-datepicker__day-name,
      .react-datepicker__day,
      .react-datepicker__time-name {
        font-size: 11px;
        color: $brand-primary;
        text-transform: uppercase;
        display: inline-block;
        width: 24px;
        line-height: 24px;
        text-align: center;
        margin: 2px;
        border-radius: 50%;
        box-shadow: none;
        outline: 0;
      }

      .react-datepicker__day--today,
      .react-datepicker__month-text--today,
      .react-datepicker__quarter-text--today,
      .react-datepicker__year-text--today {
        font-weight: 700;
        color: $brand-agency;
        background-color: $white;
      }

      .react-datepicker__day:hover,
      .react-datepicker__month-text:hover,
      .react-datepicker__quarter-text:hover,
      .react-datepicker__year-text:hover {
        background-color: $fade;
      }

      .react-datepicker__day--keyboard-selected,
      .react-datepicker__month-text--keyboard-selected,
      .react-datepicker__quarter-text--keyboard-selected,
      .react-datepicker__year-text--keyboard-selected {
        background-color: $fade;

        &.react-datepicker__day--today,
        &.react-datepicker__month-text--today,
        &.react-datepicker__quarter-text--today,
        &.react-datepicker__year-text--today {
            color: $white;
            background-color: $brand-agency;
        }
      }

      .react-datepicker__day--selected,
      .react-datepicker__day--in-range,
      .react-datepicker__month-text--selected,
      .react-datepicker__month-text--in-range,
      .react-datepicker__quarter-text--selected,
      .react-datepicker__quarter-text--in-range,
      .react-datepicker__year-text--selected,
      .react-datepicker__year-text--in-range {
        color: #ffffff;
        background-color: var(--primary);
        opacity: 1;

        &:hover {

        }
      }

      .react-datepicker__day--selected,
      .react-datepicker__day--in-range {
        color: #ffffff;
        background-color: $brand-agency;

          &:hover {
              color: #ffffff;
              background-color: $brand-agency;
          }
      }

      .react-datepicker__day--highlighted {
        color: #ffffff;
        background-color: var(--secondary);

        &:hover {
          color: #ffffff;
          background-color: var(--secondary);
        }
      }

      .react-datepicker__day--in-selecting-range,
      .react-datepicker__month-text--in-selecting-range,
      .react-datepicker__quarter-text--in-selecting-range,
      .react-datepicker__year-text--in-selecting-range {
        color: #ffffff;
        background-color: var(--primary);
        opacity: 0.75;

        &:hover {
          color: #ffffff;
          background-color: var(--primary);
          opacity: 0.75;
        }
      }

      .react-datepicker__day--selecting-range-start,
      .react-datepicker__month-text--selecting-range-start,
      .react-datepicker__quarter-text--selecting-range-start,
      .react-datepicker__year-text--selecting-range-start {
        opacity: 1;
        color: #ffffff;
        background-color: var(--primary);
      }

      .react-datepicker__day--disabled,
      .react-datepicker__month-text--disabled,
      .react-datepicker__quarter-text--disabled,
      .react-datepicker__year-text--disabled {
        cursor: default;
        color: $filter-placeholder-color;

        &:hover {
          background-color: #ffffff;
        }
      }

      .react-datepicker__day--outside-month {
        opacity: 0;
        visibility: hidden;
      }
    }
  }
}

.react-datepicker__year-read-view--down-arrow {
    border-color: #ccc !important;
    border-style: solid !important;
    border-width: 3px 3px 0 0 !important;
    content: "";
    display: block;
    height: 9px !important;
    position: absolute !important;
    top: 0px !important;
    width: 9px !important;
    left: 13px;

    &:before {
        display: none;
    }
}
