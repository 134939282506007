@import '~breakpoint-sass/stylesheets/_breakpoint';
@import './src/assets/syles/variables';
@import './src/assets/syles/utils/mixins';
@import './src/assets/syles/utils/extends';

.root {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding: 20px 20px 50px;
  margin-bottom: 30px;
  max-height: initial;

  @include breakpoint($screen-sm) {
    max-width: 800px;
    padding: 20px 18px 85px;
    margin-bottom: 0;
  }

  .container-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    @include breakpoint($screen-sm) {
      justify-content: space-between;
    }

    > div:nth-child(even) {
      margin-right: 0;
    }
  }

  .no-jobs {
    color: $night;
    font-family: $font-lato;
    font-size: 16px;
    font-weight: 600;
    border: none;
    background: transparent;

    @include breakpoint($screen-sm) {
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 21px; /* 105% */
    }
  }
}

.yellow-btn {
  background: #f7c131;
  font-family: $font-concourse;
  font-size: 18px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0;
  text-align: center;
  padding: 10px 20px;
  border-radius: 5px;
  text-transform: uppercase;
  border: none;
  box-shadow: none;
}
