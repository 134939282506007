@import './src/assets/syles/variables';
@import '~breakpoint-sass/stylesheets/_breakpoint';
@import './src/assets/syles/utils/mixins';
@import './src/assets/syles/utils/extends';

@media screen and (min-width: 768px) {
  :global(#nurse .modal.profile-modal .modal-dialog .modal-body) {
    padding: 0 0 0;
    width: 675px;
    margin: 0 auto !important;
  }

  :global(#nurse .modal.interested-modal .modal-dialog .modal-body) {
    padding: 0 0 0;
    width: 675px;
    margin: 40px auto !important;
    max-height: 100% !important;
  }

  :global(#nurse .modal.interested-modal .modal-dialog .modal-content) {
    overflow-y: auto !important;
    height: auto !important;
  }
}

@media screen and (min-width: 768px) {
  :global(.profile-modal .modal-dialog .modal-content .modal-body) {
    padding-top: 0 !important;
  }
}

.root {
  background: $white;
  max-width: 965px;
  width: 100%;
  overflow: hidden;
  margin: 18px;

  @include breakpoint($screen-sm) {
    margin: 0 auto;
  }

  .container {
    margin: 0 auto;

    .search-icon {
      height: 170px;
      width: 170px;

      svg {
        height: 170px;
        width: 170px;
      }
    }

    .text {
      font-size: 14px;
      font-weight: 700;
      padding-top: 10px;

      @include breakpoint($screen-sm) {
        font-size: 22px;
      }
    }

    .action-buttons {
      padding: 18px 0 0;
      justify-content: center;
      gap: 30px;

      @include breakpoint($screen-mobile-only) {
        background: #fff;
        position: fixed;
        bottom: 0;
        right: 0;
        left: 0;
        border-radius: 10px 10px 0 0;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
        padding: 15px 15px;
        z-index: 200;
        margin-bottom: 0;
        gap: 15px;
      }

      .submit-btn {
        @extend %yellow-button;
        background-color: #f7c131 !important;
        font-size: 14px !important;
        margin-left: 20px;
        width: 230px;
        height: 58px;

        &.disabled {
          background-color: #f7c131 !important;
          opacity: .5 !important;
          color: #000 !important;
        }

        &.loading {
          @extend %loading;

          &:after {
            right: 15px;
            margin-top: -9px;
            width: 20px;
            height: 20px;
            left: auto;
          }
        }

        &:active,
        &:hover,
        &:focus {
          background-color: rgba(247, 193, 50, 0.5529411765) !important;
          border: none !important;
          box-shadow: none;
          transition: none;
        }
      }
    }
  }
}
