// Body
$body-bg: #f5f8fa;

// Colors
$brand-primary: #303030;
$brand-nurse: #FAC00A;
$brand-recruiter: #fa8340;
$brand-recruiter-sec: #d46b30;
$brand-agency: #00b5cf;
$brand-agency-alt: #00B4D7;
$brand-agency-sec: #00b5cf;
$brand-agency-sec-darker: #288598;
$brand-info: #8eb4cb;
$brand-success: #2ab27b;
$brand-warning: #cbb956;
$brand-danger: #bf5329;
$money-green: #81c54c;
$interim-green: #60cbb3;
$aya-blue: #3084F2;
$lychee-pink: #de2e6e;
$paradise-purple: #894bbf;
$passion-red: #F43D4B;
$purple-featured: #da316d;
$disabled: #D8D8D8;
$default-text: #696B70;

$night: #303030;
$night-sec: #1f1f1f;
$silver: #a4a4a4;
$light-silver: #707070;
$silver-variant: #6A6A6A;
$fade: #dfdfdf;
$fade-sec: #d8d8d8;
$fade-third: #9b9b9b;
$fade-fourth: #bdbdbd;
$fade-fifth: #5e5e5e;
$sand: #f3f3f3;
$sand-sec: #f9f9f9;
$white: #ffffff;
$black: #000000;
$gray-sec: #ccc;
$gray-third: #e3e3e3;


// scss-docs-start spacer-variables-maps
$spacer: 1rem !default;

// Paragraphs
//
// Style p element.

$paragraph-margin-bottom:   1rem !default;

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

// scss-docs-start grid-breakpoints
$screen-xls: 320px !default;
$screen-xs: 576px !default;
$screen-sm: 768px !default;
$screen-md: 992px !default;
$screen-lg: 1280px !default;
$screen-xl:	1802px !default;
$screen-xll: 2140px !default;
$screen-mobile-only: 0 (($screen-sm)-0.00001);
// scss-docs-end grid-breakpoints

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

// scss-docs-start container-max-widths
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px
) !default;
// scss-docs-end container-max-widths

// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns:                12 !default;
$grid-gutter-width:           1.5rem !default;
$grid-row-columns:            6 !default;

// Container padding
$container-padding-x: $grid-gutter-width / 2 !default;

// Gutters
$gutter: 20px;

// Typography
//
// Font, line-height, and color for body text, headings, and more.

// Typography
$font-lato: 'Lato', sans-serif;
$font-concourse: 'Concourse Bold', sans-serif;
$font-concourse-regular: 'Concourse Regular', sans-serif;
$icon-font-path: "/fonts/";
$font-family-sans-serif: "Raleway", sans-serif;
$font-size-base: 16px;
$font-sm: 0.8em;
$font-xs: 0.6em;
$font-lg: 1.4em;
$font-xl: 2em;
$line-height-base: 1.6;
$text-color: #636b6f;

// Background
$bg-main: #F8F8F8;

// Circle Progress bar
$border-color: #00b5cf;
$bg-color: #00b5cf;
$default-size: 0.8em;
$label-font-size: $default-size / 4;
$label-font-size-redo: $default-size * 4;

// Redesign  homepage
$brand-nurse-redesign: #fdb530;
$active-dots: #22b5cf;
$filter-placeholder-color: #a2a5ac;
$hover-filter: #ededed;
$search-nav: #808080;
$search-active-nav: #fac00a;
$search-active-nav-desktop: #D99D00;
$bg-price-card: #f5f5f5;
$bg-rateSlider: #e9e9e9;
$hover-clear: #00D1F0;
$btn-interest-color: #343434;
$agencies_bg: #FAFAFA;

// link
$link: #22b5cf;
$blue-link-hover: #00d1f0;
$filter-hover: #ededed;
$bg-price-card: #f5f5f5;

//placeholder
$placeholder-color: #a2a5ac;
$no-results-color: #9a9a9a;