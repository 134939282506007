%chkcontroller {
  position: relative;
  cursor: pointer;

  &::selection {
    background: transparent;
  }

  input + span {
    background: $white;
    content: "";
    display: inline-block;
    margin: 0 .5em 0 0;
    padding: 0;
    vertical-align: middle;
    width: 20px;
    height: 20px;

    &::after {
      content: "";
      display: block;
      transform: scale(0);
      transition: transform .2s;
    }

    + span, + strong {
      display: inline-block;
      vertical-align: middle;
      width: 85%;
      margin-left: 5px;
      line-height: 14px;
      font-size: 1rem;
    }
  }

  .help {
    display: block;
    margin-left: 2em;
    margin-top: 0.35em;
  }

  input[disabled] + span,
  input:checked[disabled] + span {
    background: #dfdfdf;
  }

  input:checked + span {
    background: #86C632;
    border: 0;
  }

  input:checked + span::after {
    transform: scale(1);
  }

  input {
    position: absolute;
    cursor: pointer;
    opacity: 0;
  }
}

.green-checkbox {
  @extend %chkcontroller;

  input + span {

    &::after {
      background: url(data:image/svg+xml;charset=utf-8;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMy4wMSA4Ljk3Ij48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZmZmZjt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmNoZWNrbWFyazwvdGl0bGU+PGcgaWQ9IkxheWVyXzIiIGRhdGEtbmFtZT0iTGF5ZXIgMiI+PGcgaWQ9IkxheWVyXzEtMiIgZGF0YS1uYW1lPSJMYXllciAxIj48cGF0aCBpZD0iY2hlY2ttYXJrIiBjbGFzcz0iY2xzLTEiIGQ9Ik0xMi42NywxLjk0LDUuODQsOC42NmExLjczLDEuNzMsMCwwLDEtMiwwTC4zNCw1LjE3QTEuMTQsMS4xNCwwLDAsMSwuMTksMy43M2ExLDEsMCwwLDEsLjE1LS4xN0ExLjE3LDEuMTcsMCwwLDEsMiwzLjU2TDQuODYsNi40LDExLC4zM2ExLjE3LDEuMTcsMCwwLDEsMS42NCwwLDEuMTMsMS4xMywwLDAsMSwuMTUsMS40NEExLDEsMCwwLDEsMTIuNjcsMS45NFoiLz48L2c+PC9nPjwvc3ZnPg==) no-repeat center;
      background-size: contain;
      width: 10px;
      height: 10px;
      margin: 5px;
    }
  }
  margin: 0.25rem 0;
  width: 100%;
}

.green-checkbox-filters {
    @extend %chkcontroller;

    input + span {
        border: 1px solid #ADB5BD;
        border-radius: 4px;
        width: 15px;
        height: 15px;

        &::after {
            background: url(data:image/svg+xml;charset=utf-8;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMy4wMSA4Ljk3Ij48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZmZmZjt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmNoZWNrbWFyazwvdGl0bGU+PGcgaWQ9IkxheWVyXzIiIGRhdGEtbmFtZT0iTGF5ZXIgMiI+PGcgaWQ9IkxheWVyXzEtMiIgZGF0YS1uYW1lPSJMYXllciAxIj48cGF0aCBpZD0iY2hlY2ttYXJrIiBjbGFzcz0iY2xzLTEiIGQ9Ik0xMi42NywxLjk0LDUuODQsOC42NmExLjczLDEuNzMsMCwwLDEtMiwwTC4zNCw1LjE3QTEuMTQsMS4xNCwwLDAsMSwuMTksMy43M2ExLDEsMCwwLDEsLjE1LS4xN0ExLjE3LDEuMTcsMCwwLDEsMiwzLjU2TDQuODYsNi40LDExLC4zM2ExLjE3LDEuMTcsMCwwLDEsMS42NCwwLDEuMTMsMS4xMywwLDAsMSwuMTUsMS40NEExLDEsMCwwLDEsMTIuNjcsMS45NFoiLz48L2c+PC9nPjwvc3ZnPg==) no-repeat center;
            background-size: contain;
            width: 8px;
            height: 8px;
            margin: 4px;
        }
    }
    margin: 0.25rem 0;
    width: 100%;
}
