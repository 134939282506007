@import '../../assets/syles/variables';
@import '~breakpoint-sass/stylesheets/_breakpoint';

.featured-jobs-section {
  .job {
    color: $black;
    padding: 10px 10px 20px;
    background-color: #fff;
    border: 1px solid #d9d9d9;
    height: 144px;
    width: 200px !important;


    @include breakpoint($screen-md) {
      width: 190px !important;
    }
    @include breakpoint($screen-lg) {
      height: 144px;
      width: 220px !important;
    }
    @include breakpoint($screen-xl) {
      height: 144px;
      width: 200px !important;
    }

    img {
      width: auto;
      height: 50px;
    }


    .info {
      .a {
        fill: black;
      }
    }

    .details {
      display: flex;
    }

    .location {
      font-size: 0.7em;
      @include breakpoint($screen-md) {
        font-size: 0.9em;
      }
    }

    .specialties {
      text-transform: uppercase;
      font-size: 10px;
      font-weight: 400;
      margin-top: 5px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;

      @include breakpoint($screen-sm) {
        margin-top: 3px;
        font-size: 12px;
      }

      span {
        margin-left: 3px;
        &:first-child {
          margin-left: 0;
        }
      }
    }
  }

  .slick-track {
    display: flex;
  }

  .slick-track .slick-slide {
    display: flex;
    height: auto;
    align-items: center;
    justify-content: center;
  }

  .carousel-prev,
  .carousel-next {
    width: 12px;
    @include breakpoint($screen-xs) {
      top: 35%;
    }
    @include breakpoint($screen-md) {
      width: 15px;
    }
  }

  .carousel-prev {
    left: 18%;
    z-index: 1;
    @include breakpoint($screen-xs) {
      left: 5%;
    }
    @include breakpoint($screen-sm) {
      left: 1%;
    }
    @include breakpoint($screen-md) {
      left: -30px;
    }
  }

  .carousel-next {
    right: 18%;
    @include breakpoint($screen-xs) {
      right: 5%;
    }
    @include breakpoint($screen-sm) {
      right: 2%;
    }
    @include breakpoint($screen-md) {
      right: -30px;
    }
  }
}
