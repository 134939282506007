.img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
}

.hide-mobile {
  display: none;
  @include breakpoint($screen-sm) {
    display: block;
  }
}

.only-mobile {
  display: block;

  @include breakpoint($screen-sm) {
    display: none;
  }
}

.only-md {
  display: none;

  @include breakpoint($screen-sm $screen-lg) {
    display: block;
  }
}

.only-lg {
  display: none;

  @include breakpoint($screen-lg) {
    display: block;
  }
}

.only-mobile-md {
  display: block;

  @include breakpoint($screen-md) {
    display: none;
  }
}

.carousel-dots {
  list-style: none;
  text-align: center;
  padding: 0;

  li {
    color: $fade;
    margin: 0 5px;
    display: inline-block;

    button {
      font-size: 0;
      background-color: transparent;
      border: none !important;
      color: $fade;
    }

    button::before {
      content: '\e78b';
      font-family: 'entypo';
      font-size: 3rem;
    }

    &.slick-active {
      button {
        color: $brand-agency;
      }
    }

    @include breakpoint(max-width 320px) {
      margin: 0 2px;
    }
  }

  button::before {
    font-size: 3rem;
    line-height: 0.5;
  }
}

.carousel-vertical-dots {
  position: absolute;
  top: 20%;
  left: -75px;
  width: fit-content;

  @include breakpoint($screen-lg) {
    left: -100px;
  }

  li {
    display: block;
    color: $fade;
    margin: 0 5px;

    button {
      font-size: 0;
      background-color: transparent;
      border: none;
      color: $fade;
    }

    button::before {
      content: '\e78b';
      font-family: 'entypo';
      font-size: 3rem;
    }

    &.slick-active {
      button {
        color: $brand-agency;
      }
    }

    @include breakpoint(max-width 320px) {
      margin: 0 2px;
    }
  }

  button::before {
    font-size: 3rem;
    line-height: 0.5;
  }
}

.custom-carousel {
  .slick-initialized {
    .slick-slide {
      visibility: hidden;

      &.slick-active {
        visibility: visible;
      }
    }
  }
}

.btn-black {
  line-height: 1;
  border-radius: 2.5rem;
  box-sizing: border-box;
  border: none;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  background-color: $btn-interest-color !important;
  color: $white;
  box-shadow: none;
  font-family: 'Concourse Bold', sans-serif;
  font-size: 12px;
  padding: 10px 17px;

  @include breakpoint($screen-sm) {
    margin: 15px 0;
  }

  @include breakpoint($screen-md) {
    font-size: 16px;
    margin: 15px 0;
    padding: 12px 30px;
  }

  &:hover {
    color: $white !important;
    background-color: $black !important;
  }
}

.btn-facebook {
  line-height: 2.7;
  font-size: 0.9rem;
  border-radius: 2.5rem;
  box-sizing: border-box;
  border: none;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  padding: 0 1.5rem;
  background-color: #4267b2;
  color: $white;
  box-shadow: none;
  font-family: 'Concourse Bold', sans-serif;
  margin: 20px 0;
  font-weight: 400;

  &:hover {
    color: $white;
    border: none;
  }

  .fa {
    margin-right: 10px;
  }
}

.btn-google {
  line-height: 2.7;
  font-size: 0.9rem;
  border-radius: 2.5rem;
  box-sizing: border-box;
  border: none;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  padding: 0 1.5rem;
  background-color: #db4b38;
  color: $white;
  box-shadow: none;
  font-family: 'Concourse Bold', sans-serif;
  margin-bottom: 20px;
  font-weight: 400;

  &:hover {
    color: $white;
    border: none;
  }

  .fa {
    margin-right: 10px;
  }
}

.btn-linkedin {
  line-height: 2.7;
  font-size: 0.9rem;
  border-radius: 2.5rem;
  box-sizing: border-box;
  border: none;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  padding: 0 1.5rem;
  background-color: #1076a9;
  color: $white;
  box-shadow: none;
  font-family: 'Concourse Bold', sans-serif;
  margin-bottom: 20px;
  font-weight: 400;

  &:hover {
    color: $white;
    border: none;
  }

  .fa {
    margin-right: 10px;
  }
}

.btn-clear {
  color: #a4a4a4;
  text-decoration: none;
  cursor: pointer;
}

.text-center {
  text-align: center;
}

.text-start {
  text-align: left;
}

.text-end {
  text-align: right;
}

.full-width {
  width: 100% !important;
}

.container-search {
  display: flex;
  margin: 0 auto;
}

.bg-gray {
  background: $sand-sec;
}

.bg-white {
  background: $white;
}

.bg-agency {
  background: $agencies_bg;
}

.flex {
  display: flex;
}

.type-jobs-container {
  display: flex;
  flex-flow: row wrap;
  margin-bottom: 30px;

  @include breakpoint($screen-sm) {
    margin-bottom: 20px;
  }

  @include breakpoint($screen-md) {
    margin-bottom: 30px;
  }

  .btn {
    margin-right: 5px;
    border-radius: 13px;
    background: none;
    font-size: 11px;
    font-weight: 600;
    padding: 5px 10px;
    border: none;

    @include breakpoint($screen-sm) {
      font-size: 12px;
      margin-right: 20px;
      padding: 5px 12px;
    }

    @include breakpoint($screen-md) {
      font-size: 14px;
    }

    &--active {
      background: $brand-agency;
      color: $white;
    }

    img {
      width: 13px;
      margin-right: 5px;
      margin-top: -5px;

      @include breakpoint($screen-sm) {
        width: 20px;
        margin-right: 10px;
      }
    }

    .icon {
      width: 16px;
      height: 16px;
      margin-right: 10px;
      margin-top: -5px;

      &.active {
        .a {
          fill: $white;
        }
      }
    }

    .permanent-icon {
      height: 12px;
    }
  }
}

.link-button {
  background: transparent;
  border: none;
}

.nurse-search-control {
  text-transform: uppercase;
  border: 1px solid $fade;
  border-radius: 0;
  font-size: 12px;
  height: 35px;
  padding: 0 32px;

  &.active {
    background-color: $brand-agency;
    color: $white;
  }
}

.sidebar-container {
  display: none;
  padding-top: 40px;

  @include breakpoint($screen-md) {
    width: 25%;
    display: flex;
    padding-right: 15px;
    flex-flow: column wrap;
  }
}

.job-cards-container {
  width: 100%;
  display: flex;
  flex-flow: column wrap;
  position: relative;
  padding: 0;
  min-height: 20vh;

  @include breakpoint($screen-md) {
    width: 75%;
    padding: 0 15px;
    overflow: hidden;
    min-height: auto;
  }

  .loading-spinner {
    position: absolute;
    top: 50%;
    left: 40%;

    @include breakpoint($screen-sm) {
      left: 45%;
    }

    @include breakpoint($screen-md) {
      top: 20%;
      left: 50%;
    }
  }

  .loading-top-spinner {
    top: 10%;
  }

  .mobile-breadcrumb {
    flex-direction: column;
    @include breakpoint($screen-md) {
      flex-direction: initial;
    }

    .bread-container {
      margin-bottom: 5px;
      flex-wrap: wrap;
      line-height: 2;
      @include breakpoint($screen-md) {
        margin-top: 18px;
      }
    }
    .number-total-packages {
      margin-top: 0;
      @include breakpoint($screen-md) {
        margin-top: 16px;
      }
    }
  }

  .number-total-packages {
    font-size: 14px;
    margin-bottom: 16px;
    margin-top: 16px;
    text-align: left;
    font-weight: 700;
    text-align: right;

    @include breakpoint($screen-md) {
      margin-top: 0;
    }
  }
}

.middle-point {
  background-color: #000;
  height: 6px;
  width: 6px;
  margin: 0 5px;
  border-radius: 50%;
  display: inline-block;
}

.flex-row {
  display: flex;
  flex-flow: row nowrap;
  min-width: 0;
}

small {
  font-weight: 600;
}

.btn-only-bordered {
  line-height: 1;
  border-radius: 2.5rem;
  box-sizing: border-box;
  border: 2px solid #303030;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  color: #303030 !important;
  box-shadow: none;
  font-family: 'Concourse Bold', sans-serif;
  margin: 15px auto;
  width: fit-content;
  text-decoration: none;
  font-size: 12px;
  padding: 10px 17px;

  @include breakpoint($screen-xs) {
    font-size: 19px;
  }

  @include breakpoint($screen-sm) {
    font-size: 12px;
    margin: 15px 0;
  }

  @include breakpoint($screen-md) {
    font-size: 16px;
    margin: 15px 0;
    padding: 12px 30px;
  }

  &:hover {
    background-color: $night;
    color: $white !important;
    border: none;
  }
}

.no-result-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  min-height: 30vh;
  padding-top: 50px;

  @include breakpoint($screen-md) {
    padding-top: 3em;
    padding-bottom: 3em;
  }

  img {
    width: 100px;

    @include breakpoint($screen-sm) {
      width: 150px;
    }
  }

  .message {
    font-size: 18px;

    @include breakpoint($screen-sm) {
      font-size: 20px;
    }
  }

  .try-again {
    color: $brand-agency;
    font-size: 18px;

    @include breakpoint($screen-sm) {
      font-size: 20px;
    }
  }
}

.icon-black {
  margin-top: -3px;
  margin-right: 4px;

  .a {
    fill: $black;
  }
}

.icon-white {
  margin-top: -3px;
  margin-right: 4px;

  .a {
    fill: $white;
  }
}

.ReactModalPortal {
  .ReactModal__Overlay {
    z-index: 9999;
    background-color: rgba(48, 48, 48, 0.2) !important;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.error-message {
  color: $passion-red;
  font-size: 1em;
  margin-bottom: 10px;

  .entypo-attention {
    font-family: 'entypo', sans-serif;
  }
}

.container-custom {
  max-width: 100%;
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;

  @include breakpoint($screen-md) {
    padding: 0 30px;
  }
}

.hidden {
  display: none !important;
}

.control-sticky-bottom {
  position: fixed;
  bottom: 0;
  padding: 17px 10px;
  background: #fffffff5;
  box-shadow: 0px 0px 10px #00000015;
  z-index: 9998;

  @include breakpoint($screen-md) {
    display: none;
  }
}

.btn-check:focus + .btn,
.btn:focus {
  box-shadow: none !important;
}

.homepage-section {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 50px 15px;

  @include breakpoint($screen-sm) {
    padding: 50px 30px;
  }

  @include breakpoint($screen-md) {
    padding: 50px 30px;
  }

  @include breakpoint($screen-lg) {
    padding: 30px 87px;
  }

  @include breakpoint($screen-xl) {
    margin: 0 auto;
    max-width: 1280px;
    padding: 30px 0;
  }

  > div {
    width: 100%;

    @include breakpoint($screen-sm) {
      width: 50%;
    }
  }

  .info-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @include breakpoint($screen-sm) {
      justify-content: flex-start;
      align-items: flex-start;
    }

    .title {
      font-size: 22px;
      text-align: center;
      margin: 15px 0;
      max-width: 215px;
      line-height: 27px;

      @include breakpoint($screen-xs) {
        font-size: 30px;
        max-width: 100%;
      }

      @include breakpoint($screen-sm) {
        line-height: 40px;
        font-size: 28px;
        text-align: left;
        max-width: 100%;
      }

      @include breakpoint($screen-md) {
        font-size: 36px;
        line-height: 48px;
        text-align: left;
      }

      @include breakpoint($screen-lg) {
        font-size: 40px;
        line-height: 55px;
        text-align: left;
      }
    }

    > p {
      text-align: center;
      margin: 15px 0;
      line-height: 18px;
      font-size: 12px;
      max-width: 290px;

      @include breakpoint($screen-xs) {
        line-height: 35px;
        font-size: 24px;
        max-width: 100%;
      }

      @include breakpoint($screen-sm) {
        line-height: 22px;
        text-align: left;
        font-size: 14px;
      }

      @include breakpoint($screen-md) {
        line-height: 30px;
        font-size: 18px;
      }
    }
  }
}

.search-jobs-container {
  background: $white 0% 0% no-repeat padding-box;
  border-radius: 8px;
  box-shadow: 0 0 10px #17171739;
  color: #303030;
  padding: 20px 12px;
  display: flex;
  flex-flow: column wrap;

  @include breakpoint($screen-md) {
    width: 100%;
    max-width: 950px;
    margin-top: 20px;
  }

  @include breakpoint($screen-lg) {
    width: 950px;
    margin: 60px auto 0 auto;
  }

  @include breakpoint($screen-xl) {
    width: 1000px;
  }

  .filter-container {
    display: flex;
    flex-direction: column;

    @include breakpoint($screen-sm) {
      flex-direction: row;
    }

    &--professions {
      margin-bottom: 1rem;

      @include breakpoint($screen-sm) {
        margin-right: 15px;
        min-width: 130px;
        margin-bottom: 0px;
      }

      @include breakpoint($screen-md) {
        min-width: 200px;
        margin-bottom: 1rem;
      }
    }

    &--specialties {
      flex-grow: 2;
      flex-basis: 0;
      margin-bottom: 1rem;

      @include breakpoint($screen-sm) {
        margin-right: 15px;
        min-width: 160px;
        margin-bottom: 0px;
      }

      @include breakpoint($screen-md) {
        min-width: 200px;
        margin-bottom: 1rem;
      }
    }

    &--location {
      flex-grow: 3;
      flex-basis: 0;
      margin-bottom: 1rem;

      @include breakpoint($screen-sm) {
        margin-right: 15px;
        margin-bottom: 1rem;
        margin-bottom: 0px;
      }
    }

    &--job-types {
      margin-bottom: 1rem;

      @include breakpoint($screen-sm) {
        margin-right: 15px;
        min-width: 130px;
        margin-bottom: 0px;
      }

      @include breakpoint($screen-md) {
        min-width: 200px;
        margin-bottom: 1rem;
      }
    }

    &--action {
      .search-action {
        width: 100%;
        height: 38px;
        margin: 0;

        img {
          vertical-align: initial;
        }
      }
    }
  }
}

.visibility-hidden {
  visibility: hidden !important;
  height: 0 !important;
  margin: 0 !important;
  display: none;
}

.only-320 {
  display: block;

  @include breakpoint($screen-xls) {
    display: none;
  }
}

.only-576 {
  display: none;

  @include breakpoint($screen-xls 767px) {
    display: block;
  }
}

.only-768 {
  display: none;

  @include breakpoint($screen-sm 991px) {
    display: block;
  }
}

.only-992 {
  display: none;

  @include breakpoint($screen-md 1279px) {
    display: block;
  }
}

.since-1280 {
  display: none;

  @include breakpoint($screen-lg) {
    display: block;
  }
}

.blue-color {
  color: $brand-agency !important;
}

.btn-link {
  background: transparent;
  border: transparent;
  text-decoration: none;
  font-size: inherit;
  font-weight: inherit;
  padding: 0;
  color: $brand-agency;
}

.btn-primary {
  background-color: $night;
  color: $white;

  &:hover {
    background-color: $night;
    color: $white;
  }
}

.results-container {
  @include breakpoint($screen-md) {
    padding-top: 1em;
    padding-bottom: 3em;
  }
}

.ellipsis-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.upsell-chip {
  display: inline-flex;
  align-items: center;
  background-color: #cbeef4;
  border-radius: 20px;
  padding: 6px 10px;
  font-size: 14px;
  font-weight: bold;
  color: #2f778d;

  .icon-container {
    width: 20px;
    height: 20px;
    margin-right: 6px;
    border-radius: 50%;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .a {
      fill: #2f778d !important;
    }
  }
}

.v-align-middle {
  display: inline-block;
  vertical-align: middle;
}

.required-asterisk {
  color: #FF0C0C;
  font-family: $font-lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  vertical-align: middle;
}