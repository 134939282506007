@import "../../assets/syles/variables";

.recruiter__control {
    border-color: #cccccc;
    box-shadow: none;

    &.recruiter__control--is-focused {
        border-color: $filter-placeholder-color !important;
        box-shadow: 0 0 0 1px $filter-placeholder-color;
    }

    .recruiter__value-container {
        margin-left: 8px;
    }

    .recruiter__multi-value__remove {
        &:hover {
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
        }
    }
}

.recruiter__menu {
    z-index: 199 !important;

    &-list {
        .recruiter__option {
            label {
                padding: 3px;
                margin-bottom: 0;
            }

            label input {
                margin-top: 2px;
                margin-right: 5px;
                cursor: pointer;
            }

            label:last-child {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                width: 85%;
            }
        }
    }
}
