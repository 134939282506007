@import '../../assets/syles/variables';
@import '~breakpoint-sass/stylesheets/_breakpoint';

.how-it-works-section {
  .image-container {
    height: 300px;

    @include breakpoint($screen-sm) {
      height: 450px;
    }
  }
  .image {
    background-image: url('../../assets/images/pages/home/how-it-works.png');
    height: inherit;
    width: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    @include breakpoint($screen-sm) {
      background-image: url('../../assets/images/pages/home/how-it-works-large.png');
      margin-top: -80px;
    }
  }

  .home-btn {
    margin: 0 auto;
    width: 190px;
    color: black !important;
    @include breakpoint($screen-xs) {
      font-size: 1em;
      width: 215px;
    }
  }

  .info {
    @include breakpoint($screen-xs) {
      text-align: left;
    }
  }

  h3 {
    font-size: 16px;
    @include breakpoint($screen-xs) {
      font-size: 1.2em;
    }
  }
}
