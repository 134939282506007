@import '~breakpoint-sass/stylesheets/_breakpoint';
@import '../../assets/syles/variables';
@import '../../assets/syles/utils/mixins';


.city {
  &-card {
    display: flex;
    max-width: 100%;
    border-radius: 5px;
    box-shadow: 0px 2px 10px 0px #0000001A;
    padding: 0 !important;
    flex: 1 1;

    :global(.img-container) {
      max-width: 100%;

      > img {
        max-width: 100%;
        border-radius: 5px 5px 0 0;
        width: 100%;
        max-height: 170px;
        object-fit: cover;

        @include breakpoint($screen-md) {
          width: 100%;
          max-width: 100%;
          height: 170px;
          max-height: 170px;
        }
      }
    }

    :global(.info-container) {
      padding: 8px;

      @include breakpoint($screen-md) {
        padding: 15px;
      }
    }
  }


  &-name {
    font-family: $font-lato;
    font-size: 12px;
    font-weight: 700;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 5px;
    color: #000;
    text-transform: capitalize;

    @include breakpoint($screen-md) {
      font-size: 18px;
      line-height: 22px;
    }
  }

  &-jobs {
    font-family: Lato;
    font-size: 9px;
    font-weight: 400;
    line-height: 11px;
    letter-spacing: 0em;
    text-align: left;
    color: #343434;

    @include breakpoint($screen-md) {
      font-size: 12px;
      line-height: 14px;
    }
  }
}