@import"~breakpoint-sass/stylesheets/_breakpoint";
@import "../../assets/syles/variables";
@import "../../assets/syles/utils/mixins";

.rate-slider {
    .rc-slider-rail {
        height: 8px;
        border-radius: 18px;
        background-color: $brand-agency;
    }

    .rc-slider-track {
        height: 8px;
        border-radius: 18px;
        background-color: $bg-rateSlider;
    }

    .rc-slider-handle {
        border: 1px solid $filter-placeholder-color;
        background: $white;
        box-shadow: 0 0 6px #00000029;
        margin-top: -7px;
        height: 22px;
        width: 22px;
    }
}