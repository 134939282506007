.mask-input {
  align-items: center;
  background-color: #ffffff;
  border: 1px solid #dfdfdf;
  display: flex;
  -webkit-appearance: none;
  appearance: none;
  height: 35px;
  outline: none;
  padding: 0.75rem;
  width: 100%;
  font-size: 13px;
}
.disabled-input {
  background-color: #dfdfdf;
  color: #969696;
  border: solid 1px#E6E6E3 !important;
  opacity: 1 !important;
}