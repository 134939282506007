@import '~breakpoint-sass/stylesheets/_breakpoint';
@import '../../assets/syles/variables';

.featured-destinations-section {
  font-family: 'Lato', sans-serif;
  small {
    font-weight: 500;
    @include breakpoint($screen-sm) {
      display: block;
      font-size: 0.6em;
      margin-top: -2px;
    }
    @include breakpoint($screen-lg) {
      font-size: 0.8em;
    }
  }

  strong {
    @include breakpoint($screen-sm) {
      font-size: 0.8em;
      margin-top: -18px;
    }

    @include breakpoint($screen-lg) {
      font-size: 1.1em;
    }
  }

  h4 {
    font-family: 'Lato', sans-serif;
    text-transform: capitalize;
    font-size: 27px;
    letter-spacing: -1px;
    font-weight: 500;
    @include breakpoint($screen-xs) {
      font-size: 30px;
    }
    @include breakpoint($screen-sm) {
      font-size: 19px;
    }
    @include breakpoint($screen-lg) {
      font-size: 28px;
    }
  }

  .home-carousel-item {
    padding: 0;
    border: 1px solid #00000029;
    border-radius: 5px;
    max-width: 235px;
    height: 325px;
    box-shadow: 0px 0px 8px lightgrey;
    @include breakpoint($screen-sm) {
      max-width: 170px;
      height: 255px;
      margin-right: 10px;
    }
    @include breakpoint($screen-md) {
      margin-right: 18px;
    }
    @include breakpoint($screen-lg) {
      max-width: 250px;
      height: 340px;
    }
  }

  .carousel-image {
    height: 240px;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    img {
      width: 100%;
      height: 100%;
    }

    @include breakpoint($screen-sm) {
      height: 180px;
    }

    @include breakpoint($screen-lg) {
      height: 250px;
    }
  }

  .slick-list {
    box-sizing: initial;
    padding: 25px 0px;
  }

  .carousel-dots {
    margin: 5px 0;
    padding-top: 7px;
  }

  .home-btn {
    padding: 10px 30px;
    letter-spacing: -1px;
    @include breakpoint($screen-xs) {
      font-size: 1.2em;
    }
  }
}
