.job-types {
  color: rgb(51, 51, 51) !important;
  .jobs-count {
    float: right;
    color: white;
    font-size: 0.6em;
    padding: 2px 5px;
    border-radius: 10px;
    background: #a2a5ac;
    width: 35px;
    text-align: center;
    margin-top: 3px;
  }
  .label-icon {
    margin-right: 5px;
    padding-right: 3px;
    svg {
      width: 12px;
      height: 12px;
    }
  }
  label {
    color: #303030;
  }
  .icon {
    margin-top: -3px;
    margin-right: 4px;
    .a {
      fill: #303030;
    }
  }
}
