@import"~breakpoint-sass/stylesheets/_breakpoint";
@import "../../assets/syles/variables";
@import "../../assets/syles/utils/mixins";

.preferences-settings {
    margin-top: 40px;

    .title {
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 9px;
        letter-spacing: -0.07px;
        font-family: "Lato", sans-serif;
    }

    .info {
        color: #808080;
        font-family: "Lato", sans-serif;
        font-size: 16px;
        font-weight: 300;
        margin-bottom: 25px;

        b {
            font-style: italic;
        }
    }

    .divider {
        border: #DFDFDF 1px solid;
        margin-bottom: 35px;
        width: 100%;
        background-color: transparent;
        height: 0;
    }

    .switch-container {
        text-align: right;

        @include breakpoint($screen-sm) {
            text-align: left;
        }
    }

    .label {
        font-weight: bold;
        font-size: 12px;
        margin-bottom: 12px;
        display: block;
        width: 100%;
        color: #000;
        text-align: left;
        padding: 0;
    }

    .infoText{
        font-family: Lato;
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        letter-spacing: 0em;
        text-align: left;
        color: #808080;
    }

    .filter-date {
        width: 100%;
        margin-bottom: 20px;
        display: flex;

        .asap-control {
            text-transform: uppercase;
            border: 1px solid $fade;
            border-radius: 0;
            font-size: 12px;
            height: 35px;
            padding: 0 32px;

            &.active {
                background-color: $brand-agency;
                color: $white;
            }
        }
    }

    .alert-success {
        background-color: #81c54c;
        color: #ffffff;
        border-radius: 4px;
        padding: 5px 10px;
        display: block !important;
    }

    .profession-container {
        margin-bottom: 20px;

        @include breakpoint($screen-sm) {
            margin-bottom: 0px;
        }
    }
}

.footer-button {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 65px;
    background-color: #fff;
    z-index: 999;
}
