@import "~breakpoint-sass/stylesheets/_breakpoint";
@import "../../assets/syles/variables";
@import './src/assets/syles/utils/extends';

@media screen and (min-width: 768px) {
  :global(#nurse .modal.profile-modal .modal-dialog .modal-body) {
    padding: 0 0 50px;
    width: 675px;
    margin: 0 auto !important;
  }
}

@media screen and (max-width: 325px) {
  .modal.modal-wanderly-chat .modal-dialog .modal-content .modal-body {
    padding: 5px 3px !important;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  max-height: 100%;
  overflow: hidden;
}

.similar-jobs-list-wrapper {
  overflow: auto;
  flex-grow: 1;
}

.submit-btn {
  @extend %yellow-button;
  background-color: #f7c131 !important;
  font-size: 14px !important;
  height: 56px;
  flex: 1 1 0;
  margin-right: 0;

  @include breakpoint($screen-sm) {
    width: 276px;
    flex: initial;
  }

  &.disabled {
    background-color: #f7c131 !important;
    opacity: .5 !important;
    color: #000 !important;
  }

  &.loading {
    @extend %loading;

    &:after {
      right: 15px;
      margin-top: -9px;
      width: 20px;
      height: 20px;
      left: auto;
    }
  }

  &:active,
  &:hover,
  &:focus {
    background-color: rgba(247, 193, 50, 0.5529411765) !important;
    border: none !important;
    box-shadow: none;
    transition: none;
  }
}

.skip-btn {
  background-color: #fff;
  outline: 1px solid #000;
  font-size: 14px !important;
  color: #000;
  height: 56px;
  flex: 1 1 0;
  margin-left: 0;

  @include breakpoint($screen-sm) {
    width: 276px;
    flex: initial
  }


  &.disabled {
    background-color: #fff !important;
    opacity: .5 !important;
    color: #000 !important;
  }

  &.loading {
    @extend %loading;

    &:after {
      right: 15px;
      margin-top: -9px;
      width: 20px;
      height: 20px;
      left: auto;
    }
  }

  &:hover,
  &:active,
  &:focus {
    background-color: #000;
    color: #fff;
    outline: 1px solid #000;
    transition: none;
  }
}

.header-notification {
  color: $night;
  margin-bottom: 20px;
  font-size: 14px;
  font-weight: 700;
  padding-left: 18px;
  margin-top: 16px;

  @include breakpoint($screen-sm) {
    font-size: 24px;
    margin-top: 0;
  }

  .icon {
    height: 20px;
    width: 20px;
    margin-top: -4px;
    margin-right: 5px;

    @include breakpoint($screen-sm) {
      height: 26px;
      width: 26px;
    }
  }

  span {
    font-size: 14px;
    font-weight: 400;
    display: block;
    padding-right: 8px;

    @include breakpoint($screen-sm) {
      font-size: 18px;
      text-align: center;
      padding-right: 0;
    }
  }
}

.action-buttons {
  justify-content: center;
  gap: 30px;

  position: absolute;
  bottom: 0;
  padding: 24px 0 12px 0;
  left: 0;
  width: 100%;
  background: #fff;

  @include breakpoint($screen-mobile-only) {
    background: #fff;
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    border-radius: 10px 10px 0 0;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    padding: 15px 15px;
    z-index: 200;
    margin-bottom: 0;
    gap: 15px;
  }
}