@import '~breakpoint-sass/stylesheets/_breakpoint';
@import './src/assets/syles/variables';
@import './src/assets/syles/utils/mixins';
@import './src/assets/syles/utils/extends';

.interested-form {
  padding: 0 15px;

  @include breakpoint($screen-sm) {
    padding: 0;
  }

  .with-errors {
    border: 1px solid $passion-red;
  }
  .label {
    color: $night;
    font-family: $font-lato;
    font-size: 12px !important;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    margin: 0;
    text-align: left;
    padding-left: 0;

    @include breakpoint($screen-sm) {
      font-size: 14px;
      line-height: 24px;
      margin-top: 5px;
    }

    &-exp {
      white-space: nowrap;

      @include breakpoint($screen-sm) {
        width: 100%;
        overflow: visible;
      }
    }
  }

  .input-text {
    border-radius: 8px;
    border: 1px solid #d0d5dd;
    /* Shadow/xs */
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    height: 38px;

    &.years-exp {
      width: calc(100% - 30px);

      @include breakpoint($screen-sm) {
        width: 100%;
      }
    }
  }

  .input-text-container {
    width: 100%;
    display: block;

    @include breakpoint($screen-sm) {
      width: 100%;
    }
  }

  .input-dropdown {
    border-radius: 8px;
    border: 1px solid #d0d5dd;
    /* Shadow/xs */
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  }

  :global(.profession),
  :global(.specialty) {
    &:global(.__control) {
      border-radius: 8px;
      max-height: 38px;
      box-shadow: none;
      border: 1px solid #d0d5dd !important;

      :global(.__indicators) {
        > div {
          border: none !important;
        }
      }
    }

    &:global(.dropdown__control) {
      border-radius: 8px;
      max-height: 38px;
    }

    &:global(.dropdown__single-value--is-disabled) {
      color: #969696;
    }
  }

  :global(.specialty.__value-container) {
    padding-left: 8px;
    position: relative;
    top: 2px;
  }

  :global(.profession.dropdown__indicator-separator) {
    display: none;
  }

  .legend {
    color: $brand-agency-alt;
    font-family: $font-lato;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 200% */
    margin-bottom: 30px;

    @include breakpoint($screen-sm) {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 20px;
    }
  }

  .add-specialties-btn {
    color: #06759a;
    font-family: $font-concourse-regular;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 15px 0;

    @include breakpoint($screen-sm) {
      font-size: 16px;
      font-weight: 600;
      line-height: normal;
      padding-left: 0px;
    }

    svg {
      width: 20px;
      height: 20px;
    }
  }

  .submit-btn {
    @extend %yellow-button;
    background-color: #f7c131 !important;
    font-size: 14px !important;

    &.disabled {
      color: $night !important;
    }

    &.loading {
      @extend %loading;

      &:after {
        right: 15px;
        margin-top: -9px;
        width: 20px;
        height: 20px;
        left: auto;
      }
    }

    &:hover,
    &:active,
    &:focus {
      @extend %yellow-button;
      border: none;
      box-shadow: none;
    }
  }

  .delete-specialty-icon {
    position: absolute;
    height: 17px;
    right: 14px;
    top: 6px;
    cursor: pointer;

    @include breakpoint($screen-sm) {
      right: 20px;
      top: 46px;
    }
  }

  .checkbox-authorized {
    padding-right: 0;

    input + span {
      width: 18px !important;
      height: 18px !important;

      @include breakpoint($screen-md) {
        width: 24px !important;
        height: 24px !important;
      }
    }

    input + span::after {
      width: 10px !important;
      height: 10px !important;
      margin: 4px 4px !important;

      @include breakpoint($screen-md) {
        width: 15px !important;
        height: 15px !important;
        margin: 5px 5px !important;
      }
    }

    :global(.legend) {
      color: #303030;
      font-family: "Lato", sans-serif;
      font-size: 13px;
      font-weight: normal !important;
      font-style: normal;
      margin-left: 5px;

      @include breakpoint($screen-md) {
        font-size: 15px;
      }
    }
  }
}

.container-field {
  position: relative;
}
