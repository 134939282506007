@import"~breakpoint-sass/stylesheets/_breakpoint";
@import "../../assets/syles/variables";
@import "../../assets/syles/utils/mixins";

.specialty__control {
    border-color: #cccccc;
    box-shadow: none;

    &.specialty__control--is-focused {
        border-color: $filter-placeholder-color !important;
        box-shadow: 0 0 0 1px $filter-placeholder-color;
    }

    .a {
        fill: $night;
    }

    .specialty__value-container {
        margin-left: 8px;

        @include breakpoint($screen-sm) {
            margin-left: -30px;
        }

        @include breakpoint($screen-md) {
            margin-left: 0;
        }
    }

    .specialty__multi-value__remove {
        &:hover {
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
        }
    }

    .specialty__indicators {
        .specialty__loading-indicator {
            position: absolute;
            right: 0;
        }
    }

}

.specialty__menu {
    z-index: 199 !important;

    &-list {
        .specialty__option {
            label {
               padding: 3px;
               margin-bottom: 0;
            }

            label input {
                margin-top: 2px;
                margin-right: 5px;
                cursor: pointer;
            }

            label:last-child {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                width: 85%;
            }
        }
    }
}

.specialty.dropdown__menu {
    .dropdown__option {
        padding-left: 35px;
        font-family: "Lato",sans-serif;
        display: flex!important;
        align-items: center;
        justify-content: normal;

        .checkbox {
            position: relative;
            left: -6px !important;
            top: -1px !important;
        }

        label {
            font-size: 12px;
            cursor: pointer;
            margin: 0;
            line-height: 28px;
        }

        .jobs-count {
            height: 16px;
            background-color: #a2a5ac;
            border-radius: 9px;
            color: #fff;
            font-family: "Lato",sans-serif;
            font-size: 10px;
            padding: 3px 5px;
            display: inline-flex;
            align-items: center;
            margin-left: auto;
        }

        .checkbox {
            position: relative;
            top: 2px;
            left: -3px;
        }
    }
}
