@import '~breakpoint-sass/stylesheets/_breakpoint';
@import '../../assets/syles/variables';
@import '../../assets/syles/utils/mixins';

.specialty-faqs {
  @include breakpoint($screen-md) {
    padding: 0 40px;
  }

  &-container {
    background-color: $white;
    padding: 24px !important;
    gap: 32px;
    border-radius: 28px;


    @include breakpoint($screen-md) {
      padding: 26px 40px !important;
      margin: 0 40px;
    }
  }

  &-title {
    font-family: Lato, serif;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    margin-bottom: 10px;

    @include breakpoint($screen-md) {
      font-size: 36px;
      line-height: 43.2px;
      text-align: left;
      margin-bottom: 20px;
    }
  }

  &-excerpt {
    font-family: Lato, serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.8px;
    text-align: left;
    margin-bottom: 25px;

    @include breakpoint($screen-md) {
      font-size: 18px;
      line-height: 21.6px;
      text-align: left;
      margin-bottom: 15px;
    }
  }

  .question {
    padding: 15px 5px;
    border-bottom: 2px solid #EDEDED;

    @include breakpoint($screen-md) {
      padding: 24px;
    }

    &:last-child {
      @include breakpoint($screen-md) {
        border-bottom: none;
      }
    }

    &-title {
      font-family: Lato, serif;
      font-size: 16px;
      font-weight: 700;
      line-height: 19.2px;
      text-align: left;
      color: #06759A;
      margin-bottom: 10px;

      @include breakpoint($screen-md) {
        font-size: 20px;
        font-weight: 700;
        line-height: 24px;
        text-align: left;
        color: #06759A;
        margin-bottom: 15px;
      }
    }

    &-answer {
      font-family: Lato, serif;
      font-size: 14px;
      font-weight: 400;
      line-height: 16.8px;
      text-align: left;

      @include breakpoint($screen-md) {
        font-size: 18px;
        font-weight: 400;
        line-height: 21.6px;
        text-align: left;
      }
    }
  }
}