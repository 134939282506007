// ToolTip
.rc-tooltip {
  width: auto !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  text-transform: none !important;
  text-align: left !important;
  color: #fff;
  background: $brand-agency !important;
  border: 1px solid transparent;
  border-radius: 4px !important;
  max-width: 250px;
  padding: 8px 10px !important;

  &.place-top {
    margin-top: -4px !important;

    &:after {
      border-left: 6px solid transparent !important;
      border-right: 6px solid transparent !important;
      border-top-width: 6px !important;
      border-top-color: $brand-agency !important;
      margin-left: -6px !important;
    }
  }

  &.place-bottom {
    margin-top: 10px !important;

    &:after {
      border-left: 6px solid transparent !important;
      border-right: 6px solid transparent !important;
      border-bottom: 6px solid $brand-agency !important;
      margin-left: -6px !important;
    }
  }

  &.place-left {
    margin-left: -4px !important;

    &:after {
      border-top: 5px solid transparent !important;
      border-bottom: 5px solid transparent !important;
      border-left: 6px solid $brand-agency !important;
      margin-top: -5px !important;
      right: -6px !important;
      top: 50% !important;
    }
  }

  &.place-right {
    margin-left: 8px !important;

    &:after {
      border-top: 5px solid transparent !important;
      border-bottom: 5px solid transparent !important;
      border-right: 6px solid $brand-agency !important;
      margin-top: -5px !important;
      left: -6px !important;
      top: 50% !important;
    }
  }

  &.show {
    opacity: 1 !important;
  }

  &.dashboard {
    text-align: center !important;
    padding: 6px !important;
    background-color: #A2A5AC !important;

    &.place-top {
      margin-top: -4px !important;

      &:after {
        border-left: 6px solid transparent !important;
        border-right: 6px solid transparent !important;
        border-top-width: 6px !important;
        border-top-color: #A2A5AC !important;
        margin-left: -6px !important;
      }
    }
  }
}
