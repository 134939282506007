@import '~breakpoint-sass/stylesheets/_breakpoint';
@import './src/assets/syles/variables';
@import './src/assets/syles/utils/mixins';
@import './src/assets/syles/utils/extends';

.root {
  display: flex;
  flex-direction: column;
  border: 2px solid #e3e3e3;
  border-radius: 3px;
  max-width: 100%;
  color: $night;
  font-family: $font-lato;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  position: relative;
  margin-right: 0;
  margin-bottom: 28px;
  width: 100%;

  @include breakpoint($screen-sm) {
    margin-right: 20px;
    max-width: 292px;
    height: fit-content;
  }

  .corner-uncheck-icon {
    position: absolute;
    border: 4px solid $white;
    top: -18px;
    right: -15px;
    height: 38px;
    width: 38px;
    background-color: $white;

    svg {
      height: 30px;
      width: 30px;
    }

    @include breakpoint($screen-sm) {
      top: -20px;
      right: -20px;
    }
  }

  .corner-check-icon {
    position: absolute;
    border: 4px solid $white;
    top: -18px;
    right: -15px;
    height: 38px;
    width: 38px;
    justify-items: center;
    align-items: center;
    display: flex;
    background-color: #00B5CF;
    border-radius: 100px;

    svg {
      width: 16px;
      height: 12px;
      flex-grow: 1;
    }

    @include breakpoint($screen-sm) {
      top: -20px;
      right: -20px;

      svg {
        width: 14px;
        height: 14px;
      }
    }
  }

  .main-info {
    display: flex;
    flex-direction: row;
    padding: 9px 13px 12px 6px;
    position: relative;

    .logo {
      max-width: 66px;
      margin-right: 9px;
    }

    .description-job {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-left: 5px;
      @extend %text-ellipsis;

      .main-desc {
        margin-bottom: 6px;
        display: flex;
        @extend %text-ellipsis;

        .profession {
          color: $night;
          font-family: $font-lato;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          text-transform: uppercase;
          margin-right: 4px;
        }

        .specialties {
          color: $night;
          font-family: $font-lato;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          text-transform: uppercase;
          @extend %text-ellipsis;
        }
      }

      .location {
        color: $night;
        font-family: $font-lato;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 11px;
      }

      .features-job {
        display: grid;
        grid-template-columns: 30% 45% auto;

        color: $night;
        font-family: $font-lato;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        > div {
          @extend %text-ellipsis;
        }
      }

      .features-icon {
        width: 9.359px;
        height: 9.36px;
        fill: #ababab;
        position: relative;
        top: -1px;
      }
    }
  }

  .price-info {
    display: flex;
    flex-direction: row;
    background: #f5f5f5;
    padding: 10px 15px;
    align-items: center;

    > div {
      flex: 1 1;
    }

    .view-details {
      text-align: left;
      min-width: 130px;

      > button {
        border: none;
        background-color: transparent;
        color: #475467;
        font-family: $font-lato;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }

    .price-container {
      text-align: right;

      .total {
        font-family: $font-lato;
        color: $night;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        display: flex;
        justify-content: end;
        align-items: center;

        > small {
          font-family: $font-lato;
          color: $night;
          font-size: 10.499px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          margin-left: 3px;
        }
      }
    }
  }
}

.checked {
  border-color: #00B5CF;
}
