@import"~breakpoint-sass/stylesheets/_breakpoint";
@import "./src/assets/syles/variables";
@import "./src/assets/syles/utils/mixins";
@import './src/assets/syles/utils/extends';

.rate {
  font-family: $font-lato;
  color: $night;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
  justify-content: end;
  align-items: center;

  > small{
    font-family: $font-lato;
    color: $night;
    font-size: 10.499px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-left: 3px;
  }
}
