@include font-face(
  'Concourse Bold',
  '../../fonts/ConcourseC4Bold',
  bold,
  otf,
  ttf
);

@include font-face(
  'Concourse Regular',
  '../../fonts/ConcourseC4Regular',
  regular,
  otf,
  ttf
);

@font-face {
  font-family: 'funkydori';
  src: url('https://use.typekit.net/af/fa2b97/00000000000000007735b420/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/fa2b97/00000000000000007735b420/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
      format('woff'),
    url('https://use.typekit.net/af/fa2b97/00000000000000007735b420/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
      format('opentype');
  font-display: auto;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
}

@font-face {
  font-family: 'bebas-neue-pro';
  src: url('https://use.typekit.net/af/2ba20d/00000000000000007735c8b6/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/2ba20d/00000000000000007735c8b6/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
      format('woff'),
    url('https://use.typekit.net/af/2ba20d/00000000000000007735c8b6/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
      format('opentype');
  font-display: auto;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
}

.bebas-neue-pro {
  font-family: 'bebas-neue-pro', sans-serif;
}

// Global
html,
body {
  font-family: $font-lato;
  font-size: $font-size-base;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}
