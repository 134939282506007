@import '../../assets/syles/variables';
@import '~breakpoint-sass/stylesheets/_breakpoint';

@include breakpoint($screen-sm) {
    .no-border {
        .bread-container{
            margin: 0 !important;
        }
    }
}

.bread-container{
    display: flex;
    align-items: center;
    font-size: 10px;
    text-align: left;
    font-weight: bold;
    color:#343434;
    margin: 18px 0;
    @include breakpoint($screen-sm) {
        margin: 10px 0 0 20px;
        font-size: 15px;
    }
    &.clickable {
        div{
            cursor: pointer;
        }
        div:last-child{
            cursor: auto;
        }
    }
    .b-icon{
        margin: 0 5px;
        height: 14px;
        width: 7px;
        @include breakpoint($screen-sm) {
            height: 18px;
            width: 9px;
        }
    }
}
.flex-container{
    display: flex;
    justify-content: space-between;
}