@import '~breakpoint-sass/stylesheets/_breakpoint';
@import '../../assets/syles/variables';

.featured-agencies-section {
  .slider-container {
    max-width: 500px;
    margin: 0 auto;
    @include breakpoint($screen-sm) {
      max-width: 1200px;
    }
  }

  .agency-logo {
    height: 55px;
    width: 110px !important;
    img {
      height: 100%;
      width: 100%;
      -webkit-filter: grayscale(100%) opacity(50%);
      filter: grayscale(100%) opacity(50%);
      &:hover{
        -webkit-filter: grayscale(0) opacity(100%);
        filter: grayscale(0) opacity(100%);
      }
    }
  }

  .slick-track {
    display: flex;
  }

  .slick-track .slick-slide {
    display: flex;
    height: auto;
    align-items: center;
    justify-content: center;
  }

  .carousel-prev {
    left: 5px;
  }

  .carousel-next {
    right: 5px;
  }

  .carousel-prev,
  .carousel-next {
    width: 12px;
    @include breakpoint($screen-xs) {
      top: 30px;
    }
    @include breakpoint($screen-md) {
      width: 15px;
    }
  }
}
