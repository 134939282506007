@import '~breakpoint-sass/stylesheets/_breakpoint';
@import '../../assets/syles/variables';
@import '../../assets/syles/utils/mixins';
@import '../../assets/syles/utils/typography';

.stat-counter-section {
  background: $white;
  padding: 0 0 9px;

  .stats-counter {
    @extend .bebas-neue-pro;
    display: flex;
    justify-content: center;

    &-main {
      @include breakpoint($screen-md) {
        justify-content: space-around;
        align-items: center;
      }
    }

    &-box {
      margin-bottom: 30px;
      text-align: center;
      margin-right: 30px;

      @include breakpoint($screen-md) {
        min-width: 269px;
        margin-right: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }

    &-sign {
      font-size: 50%;
    }

    &-content {
      border-radius: 3px;
      border-bottom: 2px solid #d6d6d6;
      padding: 0 30px;
      font-size: 50px;
      letter-spacing: 0;
      color: #ffffff;
      text-shadow: 0 1px 0 #0000001f;
      position: relative;
      font-family: Avenir Next Condensed;
      @include breakpoint($screen-xs) {
        font-size: 66px;
      }
      @include breakpoint($screen-md) {
        font-size: 80px;
        max-height: 122px;
      }
      @include breakpoint($screen-xl) {
        font-size: 90px;
      }

      background: rgba(2, 189, 222, 1);
      background: -moz-linear-gradient(
        top,
        rgba(2, 189, 222, 1) 0%,
        rgba(2, 189, 222, 1) 50%,
        rgba(91, 213, 234, 1) 50%,
        rgba(91, 213, 234, 1) 100%
      );
      background: -webkit-gradient(
        left top,
        left bottom,
        color-stop(0%, rgba(2, 189, 222, 1)),
        color-stop(50%, rgba(2, 189, 222, 1)),
        color-stop(50%, rgba(91, 213, 234, 1)),
        color-stop(100%, rgba(91, 213, 234, 1))
      );
      background: -webkit-linear-gradient(
        top,
        rgba(2, 189, 222, 1) 0%,
        rgba(2, 189, 222, 1) 50%,
        rgba(91, 213, 234, 1) 50%,
        rgba(91, 213, 234, 1) 100%
      );
      background: -o-linear-gradient(
        top,
        rgba(2, 189, 222, 1) 0%,
        rgba(2, 189, 222, 1) 50%,
        rgba(91, 213, 234, 1) 50%,
        rgba(91, 213, 234, 1) 100%
      );
      background: -ms-linear-gradient(
        top,
        rgba(2, 189, 222, 1) 0%,
        rgba(2, 189, 222, 1) 50%,
        rgba(91, 213, 234, 1) 50%,
        rgba(91, 213, 234, 1) 100%
      );
      background: linear-gradient(
        to bottom,
        rgba(2, 189, 222, 1) 0%,
        rgba(2, 189, 222, 1) 50%,
        rgba(91, 213, 234, 1) 50%,
        rgba(91, 213, 234, 1) 100%
      );

      justify-content: center;
      display: flex;
      align-items: center;

      &:before,
      &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        margin: auto 0;

        width: 8px;
        height: 21px;
        /* UI Properties */
        background: transparent
          linear-gradient(180deg, #ffffff 0%, #fcfdfd 10%, #b0ced3 100%) 0% 0%
          no-repeat padding-box;
        border: 1px solid #98b0b4;
        opacity: 0.77;
      }

      &:before {
        left: 0;
      }
    }

    &-title {
      font-size: 12px;
      letter-spacing: 0.8px;
      color: $black;
      text-transform: uppercase;
      margin-top: 13px;
      @include breakpoint($screen-xs) {
        font-size: 14px;
      }
      @include breakpoint($screen-md) {
        font-size: 18px;
        margin-top: 8px;
      }
      @include breakpoint($screen-xl) {
        font-size: 20px;
      }
    }
  }
}

.wanderly-app-section {

  @include breakpoint($screen-md) {
    background-image: url('../../assets/images/background/wanderly_app_bg.svg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom;
  }

  @include breakpoint($screen-lg) {
    background-position: 25% 76%;
  }

  .stat-counter-section {
    background: transparent;
  }
}

.wanderly-title {
  @include breakpoint($screen-md) {
    padding-left: 17px;
    margin-bottom: 15px;
  }

  &.text-center {
    text-align: center !important;
    padding-left: 30px;
  }

  .label-title {
    text-align: center;

    @include breakpoint($screen-md) {
      text-align: justify;
      font-size: 36px;
    }
  }

  p {
    text-align: center;

    @include breakpoint($screen-md) {
      text-align: justify;
    }
  }
}

.logos-qr-container {
  display: flex;
  justify-content: center;

  @include breakpoint($screen-md) {
    justify-content: start;
  }

  &-modal {
    justify-content: center;
  }

  .logos-container {
    display: flex;
    flex-direction: row;

    @include breakpoint($screen-md) {
      width: 270px;
      flex-direction: column;
    }

    > a {
      margin-top: 9px;

      @include breakpoint($screen-lg) {
        margin-top: 5px;
      }

      @include breakpoint($screen-xl) {
        margin-top: 6px;
      }
    }

    .app-logos {
      height: 100px;
      width: auto;
    }
  }

  .qr-container {
    display: none;

    @include breakpoint($screen-md) {
      display: flex;
      flex: 1;
      flex-direction: column;
    }

    > p {
      font-family: Lato;
      font-size: 14px;
      font-weight: 700;
      line-height: 16.8px;
      text-align: center;
      width: 165px;
      margin-top: 20px;

      @include breakpoint($screen-lg) {
        margin-top: 12px;
      }

      @include breakpoint($screen-xl) {
        margin-top: 15px;
      }
    }

    .qr-img {
      height: 100px;
      width: 100px;
      margin-left: 31px;
      margin-top: 1px;

      @include breakpoint($screen-lg) {
        margin-top: 0;
      }
    }
  }

  .playstore_logo {
    @include breakpoint($screen-md) {
      position: relative;
      top: -18px;
    }
  }
}

.candidate-manager-p {
  font-family: Lato;
  font-size: 12px !important;
  font-weight: 400;
  line-height: 14.4px !important;
  text-align: center !important;
  margin: 25px 0;

  @include breakpoint($screen-md) {
    font-family: Lato;
    font-size: 16px !important;
    font-weight: 400;
    line-height: 19.2px !important;
    text-align: left !important;
    width: 450px;
    margin: 0;
  }

  &.text-center {
    text-align: center !important;

  }
}
