@import '~breakpoint-sass/stylesheets/_breakpoint';
@import '../../assets/syles/variables';

html,
body {
  max-width: 100%;
  overflow-x: hidden !important;
}

.homepage-container {
  h2 {
    @include breakpoint($screen-sm) {
      font-size: 32px;
    }
    @include breakpoint($screen-lg) {
      font-size: 38px;
    }
  }
  p {
    font-size: 12px;
    @include breakpoint($screen-xs) {
      font-size: 14px;
    }
    @include breakpoint($screen-sm) {
      font-size: 16px;
    }
    @include breakpoint($screen-lg) {
      font-size: 18px;
    }
  }
  .carousel-prev,
  .carousel-next {
    z-index: 1;
    cursor: pointer;
  }
}

.home-container-fluid {
  margin-right: auto;
  margin-left: auto;
  max-width: 1200px;
}

.home-btn {
  font-family: 'Concourse Bold', sans-serif;
  border: none;
  color: black !important;
  font-size: 0.8em;
  padding: 10px;
  font-weight: bold;
  letter-spacing: 0.5px;
  background-color: #9be0eb;
  border-radius: 5px;
  transition: all 0.15s;
  @include breakpoint($screen-xs) {
    font-size: 1em;
  }

  &:hover {
    background-color: #22b5cf;
    color: #ffffff !important;
  }
}
