@import "../../assets/syles/variables";

.dropdown__control {
    width: 100%;
    background-color: $white;
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    height: 38px;
    font-size: 13px;
    border-color: #cccccc;
    box-shadow: none;

    &.is-multi {
        height: auto;
    }

    &.dropdown__control--is-focused {
        border-color: $filter-placeholder-color !important;
        box-shadow: 0 0 0 1px $filter-placeholder-color;
    }

    .a {
        fill: rgb(168, 168, 168);
    }

    .dropdown__value-container {
        margin-left: 8px;

        &.profession {
            margin-left: 10px;
        }

        .dropdown__placeholder {
            color: #A2A5AC;
            margin-left: 2px;
            margin-right: 2px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            box-sizing: border-box;
        }

    }

    .dropdown__indicator-separator {
        display: none;
    }

    .dropdown__dropdown-indicator {
        margin-top: 6px;

    }

    .dropdown__indicators {
        .dropdown__control {
            border: none !important;
            height: 20px !important;
            box-shadow: none;
            min-height: 20px;
            position: relative;
            top: 2px;

            &:hover {
                box-shadow: inherit;
                border-color: #cccccc !important;
            }
        }

        .icon-close {
            height: 16px;
            width: 16px;
            cursor: pointer;

            .a {
                fill: #dfdfdf;

                &:hover {
                    fill: #FFBDAD;

                    + .b {
                        fill: #DE350B;
                    }
                }
            }

            .b {
                fill: #FFFFFF;
            }
        }
    }
}