@import '~breakpoint-sass/stylesheets/_breakpoint';
@import '../../assets/syles/variables';
@import '../../assets/syles/utils/mixins';
@import '../../assets/syles/utils/extends';

.job-card {
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 10px #00000015;
  border: 1px solid $fade;
  margin-bottom: 20px;
  z-index: 1;
  cursor: pointer;
  position: relative;
  transition: all 0.1s;
  color: #303030 !important;

  &:hover {
    transition: all 0.1s;
    box-shadow: 0 0 20px #00000048;

    .white-container {
      .interested-container {
        border-left: 1px solid #bcbcbc;
      }

      .job-info {
        &--requirements {
          .icon {
            transition: all 0.1s;
            fill: #bcbcbc;
          }
        }
      }
    }

    .gray-container {
      background-color: #ebebeb;
    }
  }

  .tag-container {
    background: $white;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99;

    &--job {
      width: fit-content;
      font-size: 8px;
      font-weight: 600;
      padding: 3px 10px;

      @include breakpoint($screen-sm) {
        font-size: 10px;
        padding: 5px 10px;
      }

      .info-icon {
        height: 8px;
        margin-right: 5px;

        @include breakpoint($screen-sm) {
          height: 15px;
        }
      }

      &.featured {
        background-color: $purple-featured;
        color: $white;
      }

      &.rapid-response {
        background-color: $money-green;
        color: $white;
      }

      &.rapid-staff {
        background-color: #9c61c8;
        color: $white;
      }

      &.crisis {
        background-color: $aya-blue;
        color: $white;
      }

      &.workstoppage {
        background-color: $brand-recruiter;
        color: $white;
      }

      &.interim {
        background-color: #00d4bb;
        color: $white;
      }
    }
  }

  .white-container {
    display: grid;
    grid-template-columns: 97px minmax(0, 1fr);
    padding: 30px 0 15px 0;
    background: $white;
    position: relative;

    @include breakpoint($screen-sm) {
      grid-template-columns: 135px minmax(0, 1fr) 180px;
    }

    @include breakpoint($screen-xl) {
      grid-template-columns: 160px minmax(0, 1fr) 220px;
    }

    .pay-package-container {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      padding: 0 10px;

      .pay-package-number {
        font-size: 20px;
        text-align: center;
        font-weight: 600;
        margin-top: -10px;
      }

      .pay-package-label {
        font-size: 10px;
        text-align: center;
        font-weight: 400;
        margin-top: -3px;
      }

      @include breakpoint($screen-xl) {
        margin: 0 auto;
      }

      img {
        max-width: 80px;

        @include breakpoint($screen-sm) {
          max-width: 115px;
        }
      }
    }

    .interested-container {
      display: none;

      @include breakpoint($screen-sm) {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        border-left: 1px solid #dfdfdf;
        padding: 0 18px;
      }
    }

    .job-info {
      display: flex;
      flex-flow: column nowrap;

      @include breakpoint($screen-sm) {
        flex-flow: row nowrap;
        max-height: 51px;
      }

      @include breakpoint($screen-xl) {
        padding-left: 20px;
      }

      &--location {
        width: 100%;
        padding: 0 10px;
        margin-bottom: 3px;

        @include breakpoint($screen-sm) {
          margin-bottom: 0;
          width: 40%;
        }

        @include breakpoint($screen-lg) {
          width: 50%;
        }

        .overview-info {
          flex: 1 1 0;
          font-size: 12px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          min-width: 0;
          max-height: 21px;
          margin-bottom: 5px;

          .profession {
            text-transform: uppercase;
            font-size: 16px;
            font-weight: 600;

            .car-icon {
              margin-top: -3px;
            }

            .icon-black {
              width: 12px;
              height: 12px;
            }
          }

          .specialties {
            text-transform: uppercase;
            font-size: 10px;
            font-weight: 400;
            margin-top: 5px;
            margin-left: 3px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;

            @include breakpoint($screen-sm) {
              margin-top: 3px;
              font-size: 12px;
            }

            span {
              margin-left: 3px;
            }
          }
        }

        .location {
          font-size: 12px;
          font-weight: 400;

          @include breakpoint($screen-sm) {
            font-size: 14px;
          }
        }

        .employment_type {
          border-radius: 9px;
          margin-left: auto;
          font: normal normal 900 10px/12px Lato;
          letter-spacing: 0;
          padding: 2px 6px;
          margin-left: 8px;
          display: flex;
          justify-content: center;
          align-items: center;

          &--1 {
            background: #fcebbb;
            color: #a07400;
          }

          &--2 {
            background: #d3e6bc;
            color: #355b01;
          }

          &--3 {
            background: #cbeef4;
            color: #06759a;
          }
        }
      }

      &--requirements {
        width: 100%;
        padding: 0px 10px;
        display: flex;
        flex-flow: row wrap;

        @include breakpoint($screen-sm) {
          width: 60%;
        }

        @include breakpoint($screen-lg) {
          width: 50%;
        }

        .beds,
        .duration {
          @include breakpoint($screen-sm) {
            margin-top: 5px;
            margin-bottom: 5px;
          }
        }

        div {
          flex: 1 1 0;
          font-size: 9px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          @include breakpoint($screen-sm) {
            flex: none;
            width: 50%;
            font-size: 12px;
            max-height: 21px;
          }
        }

        .icon {
          width: 7px;
          height: auto;
          margin-right: 3px;
          margin-top: -2px;
          pointer-events: none;
          fill: #dfdfdf;
          transition: all 0.1s;

          @include breakpoint($screen-sm) {
            width: 12px;
            height: 12px;
            margin-right: 5px;
          }
        }
      }
    }
  }

  .gray-container {
    background-color: $bg-price-card;
    padding: 4px 8px;
    text-align: left;

    @include breakpoint($screen-sm) {
      text-align: right;
    }

    .price-container {
      display: inline-block;
      margin-right: 8px;

      .sign {
        font-size: 85%;
        font-weight: 600;
        margin-top: -5px;
        display: inline-block;
        transform: translate3d(0, -5px, 0);
      }

      .separator {
        margin-right: 5px;
      }

      .price {
        font-size: 20px;
        font-weight: 600;
        margin-right: 2px;
      }

      .price-contact {
        font-size: 14px;
        font-weight: 600;
        margin-right: 2px;

        @include breakpoint($screen-md) {
          font-size: 20px;
        }
      }
    }

    .interested-container {
      float: right;
      padding-top: 4px;
    }
  }

  .btn-interested {
    font-size: 8px;
    padding: 7px 10px;
    line-height: 1;
    z-index: 2;
    letter-spacing: 0px;
    background-color: $btn-interest-color !important;

    @include breakpoint($screen-sm) {
      font-size: 12px;
      padding: 7px 22px;
      margin-top: 10px;
    }

    &.active,
    &:hover {
      background-color: $black !important;
      opacity: 1 !important;
    }

    &.mark-interested {
      transition: opacity 0.5s;
      opacity: 0.5 !important;
      border: none;
      box-shadow: none;
      cursor: not-allowed;
    }

    &.loading {
      @extend %loading;
    }
  }
}
