@import "../../assets/syles/variables";

.control-container{
    margin-bottom: 30px;
    padding: 0;
}

.link-button {
    background: transparent;
    border: none;
}

.nurse-search-control {
    text-transform: uppercase;
    border: 1px solid $fade;
    border-radius: 0;
    font-size: 12px;
    height: 35px;
    padding: 0 32px;

    &.active {
        background-color: $brand-agency;
        color: $white;
    }
}

.label {
    font-weight: bold;
    font-size: 12px;
    margin-bottom: 12px;
    display: block;
    width: 100%;
}

.assignment-container {
    display: flex;
    flex-direction: row;

    .assignment-button {
        flex-grow: 1;
        padding: 0;

        &.active {
            .color {
                fill: $white;
            }
        }
    }
}
