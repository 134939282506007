@import"~breakpoint-sass/stylesheets/_breakpoint";
@import "../../assets/syles/variables";
@import "../../assets/syles/utils/mixins";

.container-navbar-settings {
    box-shadow: 0px 3px 8px #E2E2E280;
    @include breakpoint($screen-md) {
        padding: 0 30px;
    }

    .navbar-search-jobs {
        display: none;

        @include breakpoint($screen-md) {
            display: flex;
        }

        &--item {
            color: $search-nav;

            @include breakpoint($screen-md) {
                padding: 26px 34px 33px !important;
                max-height: 79px;
            }

            .link {
                color: $search-nav;
                margin-top: 0;
                font-family: $font-lato;
                font-size: 14px;
                font-weight: 400;

                &.selected {
                    padding-bottom: 11px;
                    color: $search-active-nav-desktop;
                    border-bottom: 2px solid $search-active-nav-desktop;
                }
            }

            &:hover {
                background-color: #F9F7F5 !important;
            }
        }
    }

    &.mobile-nav {
        padding: 0;
        color: $white;

        .banner-search {
            background: $search-active-nav;
            font-size: 16px;
            padding: 10px;
            position: fixed;
            z-index: 200;
            color: $white;
            display: flex;
            justify-content: center;
            min-height: 50px;

            @include breakpoint(400px) {
                font-size: 18px;
            }

            .text {
                margin-top: 3px;
            }

            &--item {
                flex-grow: 1;
                flex-basis: 0;

                &.text {
                    flex-grow: 3;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                &.back {
                    font-size: 14px;
                    font-weight: bold;

                    .icon-white,
                    span {
                        cursor: pointer;
                        position: relative;
                        top: 5px;
                    }
                }

                &.settings {
                    .icon-white {
                        width: 23px;
                        height: 23px;
                    }
                }

                .icon-white {
                    cursor: pointer;

                    .a {
                        fill: $white;
                    }
                }
            }
        }

        .navbar-search-jobs {
            display: flex;
            flex-flow: row wrap;

            &--item {
                flex-grow: 1;
                flex-basis: 0;
                text-align: center;
                padding: 0;
                border: 1px solid #ffffff;
                margin-top: 51px;

                @include breakpoint($screen-md) {
                    margin-top: 0;
                }

                .link {
                    background: $search-active-nav;
                    display: flex;
                    flex-direction: column;
                    margin-bottom: 0;
                    color: $white;
                    padding: 23px 7px 15px;
                    border: none;
                    font-family: "Concourse Bold", sans-serif;
                    font-size: 13px;
                    height: 62px;

                    @include breakpoint($screen-sm) {
                        font-size: 16px;
                        padding: 7px;
                    }

                    .icon {
                        width: 23px;
                        height: 23px;
                        margin: 0 auto;
                        margin-bottom: 7px;

                        .add-color,
                        .cls-1,
                        .a {
                            fill: $white;
                        }
                    }

                    .title {
                        margin-top: -3px;
                    }

                    &.selected {
                        background: $white;
                        color: $search-active-nav;

                        .icon {

                            .add-color,
                            .cls-1 {
                                fill: $search-active-nav;
                            }
                        }
                    }
                }
            }

        }

    }

}
