@import"~breakpoint-sass/stylesheets/_breakpoint";
@import "../../assets/syles/variables";
@import "../../assets/syles/utils/mixins";

.pagination-wrapper {
    font-size: 10px;
    margin-top: 40px;

    .container-label-results {
    }

    .container-paginator {
        flex-grow: 2;
        display: flex;
        justify-content: flex-end;

        .rc-pagination {
            list-style: none;
            display: flex;
            flex-flow: row nowrap;
            font-weight: bold;

            .rc-pagination-prev,
            .rc-pagination-next {
                text-transform: uppercase;
                font-weight: bold;
                color: $brand-agency;
                cursor: pointer;
                text-decoration: none;
                padding: 6px 0;

                button {
                    background-color: transparent;
                    border: none;
                }
            }

            .rc-pagination-item {
                cursor: pointer;
                padding: 6px 0;
                margin: 0 10px;
            }

            .rc-pagination-item-active {
                min-width: 27px;
                height: 27px;
                font-family: inherit;
                line-height: inherit;
                text-align: center;
                vertical-align: middle;
                list-style: none;
                border-radius: 50%;
                background: $brand-agency;
                border-color: $brand-agency;
                color: $white;
            }

            .rc-pagination-jump-prev button,
            .rc-pagination-jump-next button {
                border: none;
                background: transparent;
                cursor: pointer;
                padding: 6px 0;
            }

            .rc-pagination-jump-next button::after, .rc-pagination-jump-prev button::after,
            .rc-pagination-jump-next button::after, .rc-pagination-jump-prev button::after {
                display: block;
                content: "•••";
            }

            .rc-pagination-options {
                padding: 0;
            }
        }
    }
}

.rc-select-item {
    font-size: 12px !important;
}