@import '~breakpoint-sass/stylesheets/_breakpoint';
@import '../../assets/syles/variables';
@import '../../assets/syles/utils/mixins';

#banner-wanderly {
  display: block;
  @include breakpoint($screen-md) {
    display: none;
  }
}

.search-mobile-padding-bottom {
  padding-bottom: 50px !important;
}

.coming-soon-container {
  max-width: 1000px !important;
  .info-container,
  .image-container {
    flex: 1;
    padding: 10px;
  }

  .info-container {
    p {
      color: black;
      margin: 0;
    }
    span {
      font-size: 16px;
    }

    button {
      padding: 13px 30px;
      border: solid 2px black;
    }
    .title {
      font-size: 30px;
      display: block;
      font-weight: bold;
    }
    .email-form {
      max-width: 450px;

      button,
      input {
        font-size: 16px;
        color: black;
      }
      input {
        border: solid 2px black;
        padding: 25px;
      }
      button {
        background: #f7c132;
      }
    }
    .email-form > * {
      margin-right: 15px;
    }
  }

  .image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .image-container img {
    max-width: 100%;
    max-height: 100%;
  }
}
