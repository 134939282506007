@import"~breakpoint-sass/stylesheets/_breakpoint";
@import "../../assets/syles/variables";
@import "../../assets/syles/utils/mixins";

.sidebar-controls {
    background-color: $white;
    box-shadow: 0 0 10px #00000015;
    padding: 15px;
    font-size: 12px;
    height: 100vh;
    min-height: 650px;

    @include breakpoint($screen-md) {
        height: auto;
        min-height: auto;
        max-width: 100%;
    }

    .title-container {
        font-weight: 600;
        margin-bottom: 12px;

        .clear-controls {
            color: $brand-agency;
            font-weight: bold;

            &:hover {
                color: $hover-clear;
            }
        }
    }

    .control-container{
        margin-bottom: 30px;
        padding: 0;
    }

    .rate-label-container {
        margin-bottom: 12px;
    }

    .shift-container {
        display: flex;
        flex-direction: row;

        .shift-button {
            flex-grow: 1;
            padding: 0;

            svg {
                width: 12px;
                height: 12px;

                .cls-1 {
                    fill: $black;
                }
            }

            &.active {
                .cls-1 {
                    fill: $white;
                }
            }
        }
    }

    .assignment-container {
        display: flex;
        flex-direction: row;

        .assignment-button {
            flex-grow: 1;
            padding: 0;

            &.active {
                .color {
                    fill: $white;
                }
            }
        }
    }
}