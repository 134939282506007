@import '~breakpoint-sass/stylesheets/_breakpoint';
@import '../../assets/syles/variables';
@import '../../assets/syles/utils/mixins';

.candidates-reviews-section {
  margin: 0 auto;
  flex-direction: column;
  padding-top: 30px !important;
  padding-bottom: 0 !important;
  position: relative;
  background: #f9f9f9;
  max-width: 600px;
  @include breakpoint($screen-sm) {
    max-width: 800px;
  }
  @include breakpoint($screen-md) {
    max-width: 930px;
  }
  @include breakpoint($screen-lg) {
    max-width: 1090px;
  }

  @include breakpoint($screen-sm) {
    padding-bottom: 0 !important;
  }

  .homepage-section {
    padding: 0 15px;

    @include breakpoint($screen-sm) {
      padding-top: 50px;
      padding-bottom: 50px;
    }
  }

  .title-container {
    h5 {
      &.title {
        color: $silver;
        margin-top: 0;
        font-family: 'Concourse Regular', sans-serif;
        font-weight: bold;
        text-transform: uppercase;
        font-size: 14px;
        text-align: center;

        @include breakpoint($screen-sm) {
          font-size: 14px;
          text-align: left;
        }

        @include breakpoint($screen-md) {
          font-size: 18px;
        }
      }
    }
  }

  .review-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    --bs-gutter-x: 0;
    padding: 20px 0 30px;

    @include breakpoint($screen-md) {
      padding-bottom: 45px;
    }

    .banner-container {
      text-align: center;

      @include breakpoint($screen-sm) {
        text-align: left;
      }

      h2 {
        font: normal normal bold 24px/30px Lato;
        max-width: 200px;
        margin: 0 auto 25px;
        line-height: 1.6;

        @include breakpoint($screen-xs) {
          max-width: 250px;
          font: normal normal bold 28px/36px Lato;
        }

        @include breakpoint($screen-sm) {
          font: normal normal bold 32px/40px Lato;
          margin: 0 0 25px;
        }

        @include breakpoint($screen-lg) {
          max-width: 350px;
          font: normal normal bold 38px/55px Lato;
        }
      }

      p {
        font-size: 12px;

        @include breakpoint($screen-sm) {
          font-size: 16px;
        }

        @include breakpoint($screen-lg) {
          font-size: 18px;
        }

        &.quote {
          max-width: 250px;
          margin: 0 auto 20px;
          line-height: 1.6;

          @include breakpoint($screen-sm) {
            margin: 0 0 20px;
            max-width: 300px;
          }

          @include breakpoint($screen-lg) {
            max-width: 400px;
          }
        }

        &.strong {
          font-weight: bold;
          margin-bottom: 3px;
        }
      }
    }

    .info-container {
      position: relative;

      @include breakpoint($screen-sm) {
        padding-bottom: 0;
      }

      .nurse-img {
        height: auto;
        float: right;
        z-index: 100;

        @include breakpoint($screen-sm) {
          max-width: 350px;
        }

        &.mobile {
          display: block;
          position: absolute;
          bottom: -30px;
          right: 40px;
          height: 250px;

          @include breakpoint($screen-xs) {
            height: 250px;
          }

          @include breakpoint($screen-sm) {
            height: 274px;
            width: 188px;
            bottom: -80px;
            right: -30px;
          }

          @include breakpoint($screen-md) {
            display: none;
          }
        }

        &.no-mobile {
          display: none;

          @include breakpoint($screen-md) {
            display: block;
            position: absolute;
            right: -50px;
            bottom: -93px;
            height: auto;
            width: 230px;
          }

          @include breakpoint($screen-lg) {
            bottom: -75px;
          }
        }
      }

      .stacked-carousel {
        width: 100%;
        position: relative;
        max-width: 100%;
        display: flex;
        flex-direction: column;

        .stacked-carousel-responsive-container {
          .react-stacked-center-carousel {
            padding: 0 !important;
            height: 285px !important;
            width: 100% !important;
          }
        }

        .arrow {
          &-container {
            display: flex;
            justify-content: start;

            @include breakpoint($screen-sm) {
              justify-content: center;
            }
          }

          &-container-inner {
            display: flex;
            justify-content: start;
            width: fit-content;
            background: white;
            color: #d7d7d7;
            font-size: 34px;
            font-weight: 300;
            padding: 1px 5px;
            margin-top: 10px;

            @include breakpoint($screen-sm) {
              justify-content: center;
              padding: 5px 20px;
              margin-top: 30px;
            }
          }

          &-left {
            margin: 0 20px;
            .carousel-prev {
              cursor: pointer;
              position: relative;
              top: 25px;
              left: 0;

              img {
                width: 9px;
                height: 20px;
                position: relative;
                top: -3px;
              }
            }
          }

          &-right {
            margin: 0 20px;
            .carousel-next {
              cursor: pointer;
              position: relative;
              top: 25px;
              left: 0;

              img {
                width: 9px;
                height: 20px;
                position: relative;
                top: -3px;
              }
            }
          }
        }
      }
    }
  }
}
