@import './src/assets/syles/variables';
@import '~breakpoint-sass/stylesheets/_breakpoint';
@import './src/assets/syles/utils/mixins';
@import './src/assets/syles/utils/extends';

.upload-resume-container {
  .flex-column {
    padding: 0;
  }

  .title-container {
    color: $night;
    font-family: $font-lato;
    font-style: normal;
    width: 100%;
    line-height: 24px; /* 133.333% */
    .title {
      font-size: 22px;
      font-weight: 700;
      text-transform: capitalize;
      + p {
        font-size: 18px;
        font-weight: 400;
        margin: 0;
      }
    }
    @include breakpoint($screen-sm) {
      display: none;
    }
  }

  .title-container-desktop {
    .title {
      max-width: 526px;
      font-size: 32px;
      font-weight: normal !important;
      line-height: 38px;
      letter-spacing: 0;
      text-align: left !important;

      @include breakpoint($screen-mobile-only) {
        display: none;
      }
    }
  }

  .add-file-btn {
    color: #06759a;
    font-family: $font-concourse;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    cursor: pointer;
    align-items: center;
    padding: 0 0 0 6px;

    @include breakpoint($screen-sm) {
      font-size: 18px;
      font-weight: 600;
      line-height: normal;
      padding: 0;
    }

    svg {
      width: 20px;
      height: 20px;
    }
  }

  .add-file-label {
    font-family: "Lato", sans-serif;
    font-size: 13px;
    font-weight: normal !important;
    font-style: normal;
    margin-left: 5px;
    color: #707070;
    padding: 0 0 0 6px;

    @include breakpoint($screen-sm) {
      padding: 0;
    }
  }

  .add-file-name {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 12px;
    font-weight: bold;
    margin-left: 5px;

    @include breakpoint($screen-sm) {
      font-size: 16px;
    }

    &-date {
      margin-left: 5px;
      font-family: "Lato", sans-serif;
      font-size: 13px;
      font-weight: normal !important;
      font-style: normal;
      color: #707070;
    }
  }

  .file-info-container {
    display: flex;
    align-items: center;

    background-color: #fafafa;
    border-radius: 8px;
    padding: 15px 0 15px;
    margin-top: 1em;
  }

  .update-file-btn {
    color: #06759a;
    font-weight: bold;
    cursor: pointer;
    font-size: 12px;

    @include breakpoint($screen-sm) {
      font-size: 14px;
    }
  }

  .help-block {
    color: $passion-red;
    font-size: 80%;
    font-weight: normal;
    margin-top: 2px;
    padding: 0 0 0 6px;

    @include breakpoint($screen-sm) {
      padding: 0;
    }
  }

  .input-text-container {
    label {
      font-size: 1.3em;
      font-weight: normal;
    }
  }

  .input-text {
    height: 45px;
    // width: 490px;
    //width: 344px;
    border-radius: 10px;

    @include breakpoint($screen-sm) {
      width: 490px;
    }
  }

  .checkbox {
    font-size: 18px;

    input + span {
      margin-right: 5px !important;
      width: 22px !important;
      height: 22px !important;
    }

    input:checked + span::after {
      transform: scale(1);
      width: 18px;
      height: 18px;
    }

    input:checked + span {
      background-color: #06759a !important;
    }
  }

  .submit-btn {
    padding: 5px 10px;
    height: 50px;

    @include breakpoint($screen-sm) {
      height: 56px;
      padding: 10px 25px;
    }
    &:hover,
    &:active,
    &:active,
    &:active:focus:hover,
    &:focus {
      @extend %yellow-button;
      background-color: rgba(247, 193, 50, 0.5529411765) !important;
      font-size: 14px;
      padding: 17px 25px;
      color: black !important;
      box-shadow: none !important;
      font-family: $font-concourse;
      line-height: inherit;
    }
  }

  .secondary-btn {
    padding: 5px 10px;
    height: 50px;
    background-color: transparent;
    color: black;
    border: solid 1px black;
    @include breakpoint($screen-sm) {
      height: 56px;
      padding: 10px 25px;
    }
  }

  .file-doc {
    width: 25px;
    height: 30px;

    &-container {
      margin-right: 10px;
    }
  }

  .file-name {   
    margin-right: 10px;
    max-width: calc(100% - 110px);
    @include breakpoint($screen-sm) {
      margin-right: 20px;
      max-width: calc(100% - 140px);
    }
    display: flex;
    flex-direction: column;
    flex-grow: unset;
  }
}

.submit-btn {
  @extend %yellow-button;
  background-color: #f7c131 !important;
  line-height: inherit;
  font-size: 14px;

  &:hover,
  &:active,
  &:active,
  &:active:focus:hover,
  &:focus {
    @extend %yellow-button;
    background-color: rgba(247, 193, 50, 0.5529411765) !important;
    font-size: 14px;
    padding: 17px 25px;
    color: black !important;
    box-shadow: none !important;
    font-family: $font-concourse;
    line-height: inherit;
  }

  &.loading {
    @extend %loading;

    &:after {
      right: 15px;
      margin-top: -9px;
      width: 20px;
      height: 20px;
      left: auto;
    }
  }
}

.secondary-btn {
  &.loading {
    @extend %loading;

    &:after {
      right: 15px;
      margin-top: -9px;
      width: 20px;
      height: 20px;
      left: auto;
    }
  }
}

.loading-container {
  width: 100%;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.action-buttons {
  padding: 39px 0;
  justify-content: center;
  gap: 30px;

  @include breakpoint($screen-mobile-only) {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    border-radius: 10px 10px 0px 0px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    padding: rem(10px) rem(15px);
    z-index: 200;
    margin-bottom: 0;
    gap: 15px;
  }
}
