// Font face

@mixin font-face($name, $path, $weight: null, $style: null, $exts: eot woff2 woff ttf svg) {
	$src: null;

	$extmods: (
			eot: "?",
			svg: "#" + str-replace($name, " ", "_")
	);

	$formats: (
			otf: "opentype",
			ttf: "truetype"
	);

	@each $ext in $exts {
		$extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
		$format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
		$src: append($src, url(quote($path + "." + $extmod)) format(quote($format)), comma);
	}

	@font-face {
		font-family: quote($name);
		font-style: $style;
		font-weight: $weight;
		src: $src;
	}
}

@mixin border-radius($radius) {
	$borderRadius: $radius;
	-webkit-border-radius: $borderRadius;
	-moz-border-radius: $borderRadius;
	-o-border-radius: $borderRadius;
	border-radius: $borderRadius;
}

@mixin box-shadow($shadow) {
	$shadowValue: $shadow;
	-webkit-box-shadow: $shadowValue;
	-moz-box-shadow: $shadowValue;
	box-shadow: $shadowValue;
}

@mixin opacity($opacity) {
	$opacity-ie: ($opacity * 100);  // IE8 filter
	filter: alpha(opacity=$opacity-ie);
	opacity: $opacity;
}

// String Replace for Font Face mixin
@function str-replace($string, $search, $replace: "") {
	$index: str-index($string, $search);

	@if $index {
		@return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
	}

	@return $string;
}

@mixin grab-cursor {
	cursor: url('https://www.google.com/intl/en_ALL/mapfiles/openhand.cur'), all-scroll;
	cursor: -webkit-grab;
	cursor: -moz-grab;
	cursor: -o-grab;
	cursor: -ms-grab;
	cursor: grab;
}

@mixin grabbing-cursor {
	cursor: url('https://www.google.com/intl/en_ALL/mapfiles/closedhand.cur'), all-scroll;
	cursor: -webkit-grabbing;
	cursor: -moz-grabbing;
	cursor: -o-grabbing;
	cursor: -ms-grabbing;
	cursor: grabbing;
}

// -- mixins
@mixin size($width, $height) {
    height: $height;
    width: $width;
}

@mixin draw-progress($progress, $color) {
    .pie {
        .half-circle {
            border-color: $color;
        }

        .left-side {
            transform: rotate($progress * 3.6deg);
        }

        @if $progress <= 50 {
            .right-side {
                display: none;
            }
        } @else {
            clip: rect(auto, auto, auto, auto);

            .right-side {
                transform: rotate(180deg);
            }
        }
    }
}

@mixin draw-progress--solid($progress, $color, $bg-color) {
    background: linear-gradient(to right, $color 50%, $bg-color 50%);

    &:before {
        @if $progress <= 50 {
            background: $bg-color;
            transform: rotate((100 - (50 - $progress)) / 100 * 360deg * -1);
        } @else {
            background: $color;
            transform: rotate((100 - $progress) / 100 * 360deg);
        }
    }
}

@mixin clearfix() {
    &:before,
    &:after {
        display: table; // 2
        content: " "; // 1
    }
    &:after {
        clear: both;
    }
}

@mixin hero($height: 60vh) {
    width: 100%;
    padding-right: var(--bs-gutter-x, 0.75rem);
    padding-left: var(--bs-gutter-x, 0.75rem);
    margin-right: auto;
    margin-left: auto;
    position: relative;
    height: $height;
    color: $white;
}

a {
    text-decoration: none !important;
}
