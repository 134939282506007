@import '~breakpoint-sass/stylesheets/_breakpoint';
@import '../../assets/syles/variables';
@import '../../assets/syles/utils/mixins';
@import '../../assets/syles/utils/utils';

.agencies-carousel-section {
  .slider-container {
    width: 280px;
    margin: 0 auto;

    @include breakpoint($screen-xs) {
      width: 360px;
    }

    @include breakpoint($screen-md) {
      width: 400px;
    }

    @include breakpoint($screen-lg) {
      width: 520px;
    }

    .home-carousel-item {
      @include breakpoint($screen-xs) {
        width: inherit;
      }
    }
  }

  .info-container {
    text-align: center;

    @include breakpoint($screen-sm) {
      text-align: left;
    }

    .agencies-info-desc {
      margin: 20px 45px;
      @include breakpoint($screen-xs) {
        margin-top: 20px;
        margin-bottom: 30px;
        margin-left: 40px;
        margin-right: 40px;
      }

      @include breakpoint($screen-sm) {
        margin-left: 0px;
        margin-bottom: 40px;
      }

      @include breakpoint($screen-md) {
        padding-right: 30px;
        width: 400px;
      }
    }
  }

  .home-btn {
    padding: 10px 20px;

    @include breakpoint($screen-sm) {
      padding: 13px 30px;
    }
  }

  .home-carousel-item {
    padding: 0;
  }
}
