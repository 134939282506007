@import "../../assets/syles/variables";

.frequency {
    &.dropdown__control {
        width: 100%;
        background-color: $white;
        border-color: hsl(0, 0%, 80%);
        border-style: solid;
        border-width: 1px;
        min-height: 35px !important;
        max-height: 35px !important;
        height: 35px !important;
        font-size: 13px;
        margin-bottom: 30px;
        color: $filter-placeholder-color;

        .dropdown__value-container {
            .dropdown__placeholder {
                color: #A2A5AC;
                margin-left: 2px;
                margin-right: 2px;
                position: absolute;
                top: 57%;
                transform: translateY(-50%);
                box-sizing: border-box;
            }

        }

        .dropdown__indicator-separator {
            display: none;
        }

        .dropdown__dropdown-indicator {
            margin-top: 6px;
        }
    }

}
.frequency__menu {
    z-index: 199 !important;
}
