@import '~breakpoint-sass/stylesheets/_breakpoint';
@import '../../assets/syles/variables';
@import '../../assets/syles/utils/mixins';

.section-search-controls {
  max-width: 100%;
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;

  @include breakpoint($screen-md) {
    padding: 0 30px;
  }

  .container-search {
    display: flex;
    flex-direction: column;
    padding: 1.5em 0 0;
    height: calc(100vh - 109px);
    min-height: 550px;

    @include breakpoint($screen-md) {
      height: auto;
      min-height: auto;
    }

    &--title {
      h1 {
        font-size: 20px;
        font-weight: 400;
        margin-bottom: 24px;

        @include breakpoint($screen-sm) {
          font-size: 38px;
        }
      }
    }

    &--controls {
      display: flex;
      flex-direction: row;

      &-flex {
        width: 100%;
        display: flex;
        flex-direction: column;

        @include breakpoint($screen-md) {
          width: 90%;
          flex-direction: row;
          flex-wrap: wrap;
        }

        .filter-profession {
          width: 100%;
          margin-bottom: 20px;

          @include breakpoint($screen-md) {
            width: 40%;
            margin-right: 30px;
          }
        }

        .filter-specialties {
          width: 100%;
          margin-bottom: 20px;

          @include breakpoint($screen-md) {
            width: 55%;
          }
        }

        .filter-date {
          width: 100%;
          margin-bottom: 20px;
          display: flex;

          @include breakpoint($screen-md) {
            width: 40%;
            margin-right: 30px;
          }

          .asap-control {
            text-transform: uppercase;
            border: 1px solid $fade;
            border-radius: 0;
            font-size: 12px;
            height: 35px;
            padding: 0 32px;

            &.active {
              background-color: $brand-agency;
              color: $white;
            }
          }
        }

        .filter-location {
          width: 100%;
          margin-bottom: 20px;

          @include breakpoint($screen-md) {
            width: 55%;
          }

          &.only-permanent {
            width: 100% !important;

            @include breakpoint($screen-md) {
              width: 98.5% !important;
            }

            @include breakpoint($screen-lg) {
              width: 97.5% !important;
            }

            @include breakpoint($screen-xl) {
              width: 97.5% !important;
            }

            .location__control .location__value-container {
              @include breakpoint($screen-md) {
                position: relative;
                left: -19px;
              }

              @include breakpoint($screen-lg) {
                position: relative;
                left: -26px;
              }

              @include breakpoint(1400px) {
                position: relative;
                left: -33px;
              }
            }
          }
        }
      }

      &-clear {
        width: 10%;
        display: none;

        @include breakpoint($screen-md) {
          display: flex;
          align-items: flex-end;
          padding-bottom: 15px;
        }

        .clear-controls {
          color: $brand-agency;
          font-size: 12px;
          font-weight: bold;
          flex-grow: 1;
          text-align: left;

          &:hover {
            color: $hover-clear;
          }
        }
      }
    }

    &--button {
      display: block;

      @include breakpoint($screen-md) {
        display: none;
      }
    }

    .type-jobs-container {
      margin-bottom: 1em;

      .permanent-icon {
        height: 12px;
      }

      .btn--active {
        .icon {
          fill: white !important;
        }
      }
    }
  }
}

.section-seo-search-controls {
  background-repeat: no-repeat;
  padding-top: 100px;
  margin-top: 50px;
  background-size: contain;
  display: block;

  @include breakpoint($screen-md) {
    margin-top: 0;
    background-size: 100% 271px;
    position: relative;
    padding: 200px 30px 0;
  }

  .container-seo-search {
    display: flex;
    flex-direction: column;
    padding: 0;
    background: white;

    @include breakpoint($screen-md) {
      height: auto;
      min-height: auto;
      padding: 0 0 40px;
      box-shadow: 0px 4px 15px 0px #7f5d031a;
      border-radius: 27px;
      background: white;
      margin-bottom: 35px;
    }

    &--text {
      padding: 40px 25px;
      @include breakpoint($screen-md) {
        padding: 40px 60px;
      }
    }
    &--title {
      h1 {
        color: #343434;
        font-family: Lato;
        font-size: 14px;
        font-weight: 600;
        font-style: normal;
        line-height: normal;
        margin-bottom: 10px;
        text-align: center;

        @include breakpoint($screen-sm) {
          font-size: 38px;
          margin-bottom: 20px;
          text-align: center;
        }
      }

      h2 {
        color: #343434;
        font-family: Lato;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-bottom: 15px;
        text-align: center;

        @include breakpoint($screen-sm) {
          font-size: 22px;
          margin-bottom: 30px;
          text-align: center;
        }
      }
    }

    &--title-search {
      h1 {
        font-size: 20px;
        font-weight: 400;
        margin-bottom: 40px;
        color: #343434;
        font-style: normal;
        line-height: normal;
        text-align: center;
        padding-top: 40px;

        @include breakpoint($screen-sm) {
          display: none;
        }
      }
    }

    &--desc {
      color: #606060;
      font-family: Lato, serif;
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      @include breakpoint($screen-sm) {
        padding: 0 30px;
        font-size: 18px;
      }

      button {
        background: transparent;
        border: none;
        color: $brand-agency;
      }
    }

    &--controls {
      display: flex;
      flex-direction: row;

      &-flex {
        width: 100%;
        display: flex;
        flex-direction: column;

        @include breakpoint($screen-md) {
          width: 90%;
          flex-direction: row;
          flex-wrap: wrap;
        }

        .filter-profession {
          width: 100%;
          margin-bottom: 20px;

          @include breakpoint($screen-md) {
            width: 40%;
            margin-right: 30px;
          }
        }

        .filter-specialties {
          width: 100%;
          margin-bottom: 20px;

          @include breakpoint($screen-md) {
            width: 55%;
          }
        }

        .filter-date {
          width: 100%;
          margin-bottom: 20px;
          display: flex;

          @include breakpoint($screen-md) {
            width: 40%;
            margin-right: 30px;
          }

          .asap-control {
            text-transform: uppercase;
            border: 1px solid $fade;
            border-radius: 0;
            font-size: 12px;
            height: 35px;
            padding: 0 32px;

            &.active {
              background-color: $brand-agency;
              color: $white;
            }
          }
        }

        .filter-location {
          width: 100%;
          margin-bottom: 20px;

          @include breakpoint($screen-md) {
            width: 55%;
          }

          &.only-permanent {
            width: 100% !important;

            @include breakpoint($screen-md) {
              width: 98.5% !important;
            }

            @include breakpoint($screen-lg) {
              width: 97.5% !important;
            }

            @include breakpoint($screen-xl) {
              width: 97.5% !important;
            }

            .location__control .location__value-container {
              @include breakpoint($screen-md) {
                position: relative;
                left: -19px;
              }

              @include breakpoint($screen-lg) {
                position: relative;
                left: -26px;
              }

              @include breakpoint(1400px) {
                position: relative;
                left: -33px;
              }
            }
          }
        }
      }

      &-clear {
        width: 10%;
        display: none;

        @include breakpoint($screen-md) {
          display: flex;
          align-items: flex-end;
          padding-bottom: 15px;
        }

        .clear-controls {
          color: $brand-agency;
          font-size: 12px;
          font-weight: bold;
          flex-grow: 1;
          text-align: left;

          &:hover {
            color: $hover-clear;
          }
        }
      }
    }

    &--button {
      display: block;

      @include breakpoint($screen-md) {
        display: none;
      }
    }

    .type-jobs-container {
      margin-bottom: 30px;

      .permanent-icon {
        height: 12px;
      }

      .btn--active {
        .icon {
          fill: white !important;
        }
      }
    }

    &-no-border {
      box-shadow: none;
      padding: 0 6px;
      margin-bottom: 10px;
      text-align: left;

      .container-seo-search--desc {
        @include breakpoint($screen-sm) {
          padding: 50px 110px;
        }
      }
    }
  }
}

.section-seo-search-controls-no-padding {
  padding: 50px 30px 0;
}
